import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { 
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import('@/views/RegisterPage.vue'),
  },
  // {
  //   path: '/review',
  //   name: 'ReviewPage',
  //   component: () => import('@/views/ReviewPage.vue'),
  // },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes,
});

export default router;
