import Vue from 'vue';

export const store = Vue.observable({
    GDPRShow: true,
    sideBar: {
        active: 0,
        years: [
            {
                year: '2020',
            },
            {
                year: '2019',
            },
            {
                year: '2018',
            },
            {
                year: '2017',
            },
            {
                year: '2016',
            },
        ]
    },
    teamPage: {
        teamImg: '',
        videoUrl: '',
        teamName: '',
        members: '',
        intro: '',
        fbUrl: '',
    },
    pagination: {
        nowPage: 1,
    }
});

export const mutations = {
    setActive(y) {
        store.pagination.nowPage = 1;
        store.sideBar.active = y;
    },
    setTeamPage(item) {
        store.teamPage.teamImg = item.teamImg;
        store.teamPage.videoUrl = item.videoUrl;
        store.teamPage.teamName = item.teamName;
        store.teamPage.members = item.members;
        store.teamPage.intro = item.intro;
        store.teamPage.fbUrl = item.fbUrl;
    },
    prePage() {
        if(store.pagination.nowPage > 1) {
            store.pagination.nowPage -= 1;
        }
    },
    nextPage(last) {
        if(store.pagination.nowPage < last) {
            store.pagination.nowPage += 1;
        }
    },
    toPage(v) {
        store.pagination.nowPage = v;
    },
    GDPRToggle(v) {
        store.GDPRShow = v
    }
}