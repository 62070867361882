<template>
  <div id="workshop" class="item_container ">
    <div class="img_wrapper">

      <div class="item_wrapper">
        

          <h2 class="sub_title"
            data-aos="fade"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
          >
            <img class="icon_title"
              src="https://lh3.googleusercontent.com/djtCRiDRP4NIg-dxDFbIKQBZRPMtJt7PwJJr_ls9R9XVC6UWgPXfQF79sXg62W9zgq2vaW8dJlQz0aFzmg0Ju6SXJyfUBxR9GNbluSPo8zR3h4RSNFx-XQAAkhRFJcOznZSdWkbTHw=w2400" alt=""
              data-aos="zoom-in"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
            築夢工作坊
          </h2>
          <div class="line">
            <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt=""
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
            >
          </div>

        <div class="item">
          <p class="workshop_intro"
            data-aos="fade"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
          >
            針對有興趣參與青年築夢計畫學生進一步了解第六屆活動內容。<br />
            說明會當天將邀請歷屆團隊分享精采的築夢經驗與團隊現場實作體驗，<br />
            與有志參與同學傳承並交流互動。
          </p>
        </div>
        
      <div class="item_content">
        <div class="item">

          <div class="card"
            v-for="(item, key) in dream"
            :key="'dream' + key"
            data-aos="fade-up"
						data-aos-duration="2000"
						data-aos-delay="300"
						data-aos-anchor-placement="top-bottom"
          >
            <div class="card_img" :class="{ 'active': key === active }" @click="showIntro(key)">
              <img :src="item.img">
            </div>

            <transition-group class="slide_right" name="slide_right" tag="div">
              <div class="card_item" v-show="key !== active" :key="'item1_' + key">
                <div class="card_content">
                  <h3>{{ item.company }} | <span class="h3">{{ item.name }}</span></h3>
                  <p>{{ item.position }}</p>
                  <p>講題: {{ item.title }}</p>
                </div>
              </div>
            </transition-group>

            <transition-group class="slide_left" name="slide_left" tag="div">
              <div class="card_item" :class="{ 'active': key === active }" v-show="key === active" :key="'item2_' + key">
                <div class="card_content">
                  <p class="speaker_intro">{{ item.intro }}</p>
                </div>
              </div>
            </transition-group>

          </div>
        </div>
      </div>



        <div id="enhance"></div>
        <h2 class="sub_title"
          data-aos="fade"
          data-aos-duration="2000"
          data-aos-delay="300"
          data-aos-anchor-placement="bottom-bottom"
        >
          <img class="icon_title"
            src="https://lh3.googleusercontent.com/Fq0measdXx5yhyR4XE9_95HvJnEzq7U3_YVnINbPo5Frojz4EiXF3A3Ic5CY8cMcaFNlIwrzLZSXsN3SbW-v90SZuVs54MBTx6LQomGCO8VxNxE6YI6XzjmadunQ2JLu6kCfw6yKbw=w2400" alt=""
            data-aos="zoom-in"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
          強化工作坊
        </h2>
        <div class="line">
          <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt=""
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
          >
        </div>

        <div class="item">
          <p class="workshop_intro"
            data-aos="fade"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
          >
            針對入選團隊的進階課程，協助團隊將企劃完善並協助同學解答團隊問題。<br />
            除了強化工作坊外，也邀請所有入選團隊前往台積電參訪，<br />
            第一手學習台積電在環境永續上的努力作為。<br />
          </p>
        </div>

      <div class="item_content">
        <div class="item">
          <div class="card"
            v-for="(item, key) in enhance"
            :key="'enhance' + key"
            data-aos="fade-up"
						data-aos-duration="2000"
						data-aos-delay="300"
						data-aos-anchor-placement="top-bottom"
          >

            <div class="card_img" :class="{ 'active': key === enhanceActive }" @click="singleShowIntro(key)">
              <img :src="item.img">
            </div>

            <transition-group class="slide_right" name="slide_right" tag="div">
              <div class="card_item" v-show="key !== enhanceActive" key="enhance_1">
                <div class="card_content">
                  <h3>{{ item.company }} | <span class="h3">{{ item.name }}</span></h3>
                  <p>{{ item.position }}</p>
                  <p>講題: {{ item.title }}</p>
                </div>
              </div>
            </transition-group>

            <transition-group class="slide_left" name="slide_left" tag="div">
              <div class="card_item" :class="{ 'active': key === enhanceActive }"  v-show="key === enhanceActive" key="enhance_2">
                <div class="card_content">
                  <p class="speaker_intro">{{ item.intro }}</p>
                </div>
              </div>
            </transition-group>

          </div>

        </div>

        </div>
      </div>



      <div class="item workshop_ocean_m">
        <img src="https://lh3.googleusercontent.com/RXxBAaiuP9r2Mw5KqddiasxXZ9ujepeNyTX87dSKATKgUJIV_5XhgiSGxg9XFV4Tmw50zMuVItWLiM043ENoLSU5lyZCwyV4MBL8lglZV_GDEtz3jfIjs7j6pzYZTGjbiPDpQS_tRQ=w2400" alt="">
      </div>


      <div class="item workshop_ocean">
        <img src="https://lh3.googleusercontent.com/Yys5nTSFVzOdwWsGZNverZmtfb-v2gdFU8DhGYBp4NCKNq7ApnpRKVclkROXxijvyPe1Qcm82dNkTB3j6cZuZ90MIhVkVhWZo21RJ_o2-Dj8MSjzsmGg8ZwXudWGaOe4oxPnlo65CQ=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
        >
      </div>

      <div class="item workshop_whale">
        <img src="https://lh3.googleusercontent.com/SsV2nV3t4-I9fe0FSKIJmW0AS7JxVY8KqN7c1mIcGZ7eQ8cy3T8BiLGxqwW04eQ_SIxAKfYCuofH0Y2Ar0JAhsBihTDijQo3MM5lgr5WcRnga8uTSsMF2SZa3lurq0XnQizbZKdqtA=w2400" alt=""
          data-aos="slide-left"
          data-aos-delay="200"
          data-aos-duration="1500"
          data-aos-anchor-placement="center-bottom"
        >
      </div>

      <div class="item workshop_wave_2">
        <img src="https://lh3.googleusercontent.com/YVzZi8ZidTVgxs_s0pJdH4rzzvzM9O93NZJFzzZK4iiZXU1vDy5A7yosrwUeICwFU8YkparzNS3aG6bWz59_qIRvcLuboSGjOkfJjptxcvyRm81ZMomxwVEccTAzICYQdODS0Zssxg=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
        >
      </div>

      <div class="item lighthouse">
        <img src="https://lh3.googleusercontent.com/ik-C-7-AZtPk122Md5gux5sQ9chIZOl4wQ47AG3dUtFTrk4BbUITI9t88c0ukIjR5dDlD7WHBNY4XMaLeaApeEQupTdowDzt_yGA_-3dWlek0HgL21ScHbE8lci1ySoUs3Ntw2QhfA=w2400" alt=""
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
        >
      </div>

    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        active: '',
        enhanceActive: '',
        dream: [
          {
            img: 'https://lh3.googleusercontent.com/cSd_LMEezumcBJGhTmLdyw1jPcWA-NDoRKxEMEQ7E0EASOq8cTruczzObaHCNbyU40KBuYI0mo55Uh3Gp5I7SOJHoZZy1u3ffqOnxG66RcQ5kPjXr2EMVN3n4i6Q0VXu1SH1WVhHIw=w2400',
            company: '林事務所',
            name: '林承毅',
            position: '林事務所  執行長',
            title: '疫後新生活--未來創造工作坊',
            intro: '長期以來倡導洞察力及企劃思維之重要，多年來帶領超過百場各式設計工作坊。近年來積極投入地方創生等議題的社會倡議，實踐與串連，期盼能為即將面臨高齡化，少子化困境的台灣思考解決方案。',
          },
          {
            img: 'https://lh3.googleusercontent.com/eAIAOcPsiBvcNmUL_X-T-zAGc8FKXAXwR7q4A2GexKu62uzsGvEs9wnEOEMiKvnDK0i_eIPPrqarBcHdyeuL6YF0-2PLBT2hJ87g5z_-Io952tru4W8TRfm7tzCLV4X0nDd8G6ZQcQ=w2400',
            company: 'JUST IN XX',
            name: '周裕穎',
            position: 'JUST IN XX品牌創辦人',
            title: '如何從在地飛向國際！',
            intro: '台灣第一位獲選進入紐約官方時裝週的設計師品牌。近期專注於推廣永續議題，將不同年代的舊衣、二手衣、廢棄物重製升級改造，透過再設計(Redesign)、再製造(Remake)、再利用(Reuse) 的方法為舊物、廢棄物重新注入價值。',
          },
          {
            img: 'https://lh3.googleusercontent.com/d7CxG1y5uD9cqjpLXqT0R3GmtQXl4uAFfJrSswoMo1s6CL1Ew0UUAU6jGgWTE1Db9pR-5BQC6DV9soAtWqvX-H3kMnfE_uqWzJrh89lX28IjgdllFscvWr5E0s3zA_VKnIz0Nm-JDA=w2400',
            company: '天下雜誌',
            name: '高偉倫',
            position: '天下雜誌 未來事業部數位營運主任',
            title: '疫情下，如何創造數位永續品牌影響力',
            intro: '負責《CSR@天下》、《未來城市FutureCity@天下》的網站與社群營運及⼤型品牌專案，協助天下操盤跨部⾨⼤型專案，如連續兩年「SDGs國際論壇」、「為淡⽔河做⼀件事」、「U20國際青年論壇」、「台灣設計論壇」、「天下ESG TALK線上論壇」。',
          },
          {
            img: 'https://lh3.googleusercontent.com/ftJnyqhhY43QOxOBiqHqL8Id2JE5mAyPEAH-hakYBWbB2Y1_qxHbdbZCIX0Nq_0-C1jiq8TLb1dZRvG7oq2XLiSullPSWHwGQi0rCFgdYsyFuqCooAxQfcA24ZyNytUXUYRSGmQzpg=w2400',
            company: '台積公司企業社會責任',
            name: '張麗絨',
            position: '台積公司企業永續部經理',
            title: '成就一座共好的山─台積公司ESG',
            intro: '負責台積公司ESG相關業務推廣及訊息溝通，為企業社會責任報告書、ESG網站、ESG電子報及《台積電•愛•行動》臉書粉絲專頁負責人，以身為台積人為榮。',
          },
          {
            img: 'https://lh3.googleusercontent.com/ZwZwkNhI8kqW5Niy52l_G63lowI1yiQi_pLf8WwZnfxl_eQMCCpAbbE0b9Ahqv7iAbW_Wrlc0adz2fysSs3K1gpn18qm1MiyB9LjXLbS59XHiMukUN85uVsclYHckeEGU-K5X7FChA=w2400',
            company: 'RE-THINK',
            name: '黃之揚',
            position: 'RE-THINK 共同創辦人暨執行長',
            title: '想歸想，如何創造具體社會影響力？',
            intro: '在新創圈打滾後把行銷和社群思維導入環境議題。在全台號召超過3.5萬人一起改變環境，嘗試用像是《海廢圖鑑》、《回收大百科》設計感又好玩的手法，帶領更多台灣人看見環境問題。',
          },
          {
            img: 'https://lh3.googleusercontent.com/1KRS5jkSb75XgXFHo4HmOUHDJnFqXuwPbr0DgeaQb25JwMemgV6yHN04FDf1QThWTMdtQHbg-HfoV3XP-hlR3gP9z27giscuEyeVKKtjDcx6_TAlk134trPFyPKK6YByvMAp1Ouakg=w2400',
            company: '配客嘉',
            name: '葉德偉',
            position: '配客嘉股份有限公司 創辦人暨執行長',
            title: '誰說青年就不能為世界創造影響力，讓我們一起推動我們想要的未來',
            intro: '過去學經歷為商管背景的連續創業家，並有多家外商工作經驗，關注環保永續、社會企業議題，堅信PackAge+能透過社會創業來改變整個台灣網購生態，並讓台灣的環保被世界看見。',
          },
        ],
        enhance: [
            {
            img: 'https://lh3.googleusercontent.com/RMLZiGrv_n4lVGLjILXR6hb88lluLl0NfYJFqSxK2mvtZ6Zlr5icjlNX7fTMojLzep8E1xxGD9psd-Td6L7cvE3prylv6HMt_xvmCnzl3uioZzplMAfEjd6omK0Gz6i_6tbLUS3DVQ=w2400',
            company: '炙星',
            name: '張歆浤',
            position: '炙星股份有限公司 執行長',
            title: '如何以創新思維解決問題',
            intro: '基金經理人，亦為多間企業公司的專業經理人、行銷顧問及商業培訓導師。希望結合創作及創業的力量，協助年輕人成長，並連結世界菁英人士，共同打造產業生態圈，進而改變世界。',
          },
          {
            img: 'https://lh3.googleusercontent.com/BP0T3hJIDl92fwx_YZXf1r44c3i6v5U11niqQxi9s_xF7wyU4fk3Z_PLssPGGCGiJu5V-nveeZsb6upgxnd09Jg6bdHoUYZMPOYNIC_2T-r7l-_vebZEi6uZaMGwg1mLCDlfuatN4g=w2400',
            company: '炙星',
            name: '俞韶鈞',
            position: '炙星股份有限公司 營運長',
            title: '如何以創新思維解決問題',
            intro: '於平台的營運經驗中,在以數據為基礎的營運上,透過資源整合與團隊協作,建立有效率的管理流程、回報制度、金流管控;並努力透過平台的流量傳達良善社會價值,給平台上的每一位使用者。',
          },
        ]
      }
    },
    methods: {
      showIntro(key) {
        if(this.active === key) {
          this.active = 99;
        } else {
          this.active = key;
        }
      },
      singleShowIntro(key) {
        if(this.enhanceActive === key) {
          this.enhanceActive = 99;
        } else {
          this.enhanceActive = key;
        }
      }
    },
  }

</script>

<style lang="scss" scoped>

// #enhance {
//   @apply h-4 block relative mb-12;
// }

  .item_container {
    background: url('https://lh3.googleusercontent.com/c39ZolF2bx4irDZpnarQ6QBgtFDrSI8L9obj7kT-u8Uc6g2OTSkn8RyAglYKVGJFcOVhPz4OtZXJprUdZO0x_-gvMAcSzRWVdigH51XpUfSlMizJb5PeUgHT1Fs9pZp4Ut9vkBaAjQ=w2400');
    @apply relative flex-col;

    .item_wrapper {
      @apply mx-auto lg:pb-60%;

      .item_content {
        @apply flex flex-col justify-around;

        .item {
          @apply flex w-full justify-center flex-wrap mb-8;
        }
      }
    }
  }

  .card {
    background: url('https://lh3.googleusercontent.com/p1qhEl7iZmeyOiOQkzA4BP3njx9rcWbw8-rvCVU-6bcVhCbxUqUCHCUpZHsL3PRu6pfQmscXDMeT0hyPkda85mCkgMWenzo0OAL_sgAD_ZLRPEC859fficISCbujdRuxyNakAoDiMA=w2400');
    @apply relative flex w-35rem h-40 flex-row items-center mx-1% my-4 rounded-full overflow-hidden;

    .card_img {
      @apply absolute w-20 h-20 lg:w-10rem lg:h-10rem hover:scale-110 cursor-pointer left-3% lg:left-0 z-20  duration-1000 filter drop-shadow-line;

      &.active {
        @apply opacity-40 lg:opacity-100 lg:block left-97% lg:left-full transform -translate-x-full;
      }
    }
  
    .slide_right {
      @apply h-full contents;

      .card_item {
        @apply absolute w-70 h-full pr-8 right-0 z-10;

        .card_content{
          @apply flex h-full flex-col justify-around p-2 text-base;
          
          p {
            @apply text-base;
          }
        }
      }
    }

    .slide_left {
      @apply contents;

      .card_item {
        @apply absolute -left-5% lg:w-65% z-10 w-3/4;

        &.active {
          @apply left-0;
        }

        .card_content{

          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }

          -ms-overflow-style: none;
          scrollbar-width: none; /* Firefox */

          @apply h-9rem text-base pt-4 pb-4 pl-16 overflow-y-scroll;

          .speaker_intro {
            @apply text-sm;
          }
        }
      }
    }
    
    
  }

  .workshop_intro {
    @apply text-white text-center;
  }

  .sub_title {
    @apply text-white; 
  }

  .h3 {
    @apply text-second;
  }

  .line {
    @apply filter invert;
  }


  .workshop_ocean_m {
    @apply block lg:hidden;
  }
  
  .workshop_ocean {
    @apply absolute hidden lg:block bottom-0;
  }

  .workshop_whale {
    @apply hidden w-3/5 lg:block lg:absolute right-10% bottom-6% animate-whale_rotate;
  }

  .workshop_wave_2 {
    @apply hidden w-full lg:block lg:absolute right-0 bottom-0 animate-wave_wave;

    img {
      @apply w-full;
    }
  }

  .lighthouse {
    @apply hidden w-2/5 lg:block lg:absolute left-0 bottom-0;
  }


.slide_right-enter-active {
  @apply transition delay-75 duration-700 ease-linear;
}

.slide_right-leave-active {
  @apply transition duration-300 ease-linear;
}

.slide_right-enter, .slide_right-leave-to {
  transform: translateX(100%);
}

.slide_left-enter-active {
  @apply transition delay-75 duration-700 ease-linear;
}

.slide_left-leave-active {
  @apply transition duration-500 ease-linear;
}

.slide_left-enter, .slide_left-leave-to {
  transform: translateX(-100%);
}

</style>
