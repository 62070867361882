<template>
  <div id="schedule" class="schedule item_container">
    <div class="img_wrapper">

      <div class="item_wrapper" ref="schedule">
        <div class="item_content">

          <h2 class="sub_title"
            data-aos="fade"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
            <img
              class="icon_title"
              src="https://lh3.googleusercontent.com/6j24I06AnIIGArhLaW6QDcnjVCQkwmW3DS7C4_Q_VVsh6pZ6ciAhQ4jXUNiDGfUrcZd2KYvsKjBx-8g-sRYaUkNjThGotGFfHA-Qm1r-gPPqZ7maOQ53zzz-5nKzLQcf2X2zJe1pOA=w2400"
              data-aos="zoom-in"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
            計畫期程
          </h2>
          <div class="line">
            <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt=""
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
          </div>
          <swiper class="swiper"
            ref="Swiper"
            :options="swiperOptions"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
            <swiper-slide class="swiper-slide"
              v-for="(item, key) in schueduleContent"
              :key="item.title"
              >
              <div class="item">
                <img class="img_date"
                  :src="active == key ? item.activeImg : item.img"
                  @click="clickSchuedule(key)"
                  >
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>

        </div>

        <div class="description" 
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="300"
          data-aos-anchor-placement="bottom-bottom"
          >
          <hr />
          <h3>
            {{ schueduleContent[now].title }}
          </h3>
          <p v-html="schueduleContent[now].content"></p>
        </div>
  
      </div>


      <div class="item schedule_woman">
        <img src="https://lh3.googleusercontent.com/cHGjr-_6hZLL2r5gYjoGuiaMo8clBEh43nuMiqWP2uQooJ4rlNJ0NgVHOKnU4pbg869M9eMC1VqX6XjVOUNd1Nq_3xPya2i8KH7gnEFFrINPQ8xXCvd4Tb3iamt3nOzIrfc_4EdytQ=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item schedule_man">
        <img src="https://lh3.googleusercontent.com/wiHvbxenRCQYsocxyG8SQWELWpyiUgubWe0johMzAe0rJVD4bVd__NNTk5iOCR8hPIZ3yH8hc-aasWGpXZ3mgNsvkgCmJEXmh1WE3j01O7Ld0dFtwP-31TeTho_6F90Jg-urJF2snA=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item schedule_stall">
        <img src="https://lh3.googleusercontent.com/N7ptcHDfVqHg0aRyrgRndpD8HrK4areI6yo48mLyqT01VRZ3vOBpTMYDM7dzQhzy7_3FhMPFrQxSNoK0zux1q04uwyFOrRZCIkXyblpk8qs1pgJnaUNF3WiJcNyxoC5Z9PG0MULJSQ=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item schedule_man2">
        <img src="https://lh3.googleusercontent.com/15IC11F78we2UDfG0s8364L-Cd7_VQkGcCJp1i8UAV-OHiYi53DZV3-CU22EB6BHkI8RnWm_iFan-cbWiSD27Idqj0Qw0_RkFwkUkIJrKSWuNfWMu47TrDL4PaMUuhP6GnWCf1VU5Q=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item schedule_ground">
        <img src="https://lh3.googleusercontent.com/XXG2w8NhkhCDIQ9mxJNg8Mq7_I4a1a2-UBlyMaWLTsyfooamU0SkPc467zEcVJODub42Bt8SGLjovPG1I5nqmHTCWXotT7J_litiPKUlelIvO9pEuSrqGcq86WwdZM8NK38dS715MQ=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
          >
      </div>

      <div class="item schedule_ground_m">
        <img src="https://lh3.googleusercontent.com/QB5jzeOmJZ4iRWfGSM9zD1g1ifENkXWp4RMtkLE4DiA0k_SM2q5BDt6WXJb0CNHpDC03qTiBl9zkW-aN8AltXg827Kq66eQJTVWeO1X8Xv7p4AU927sYp7YDxbwPRoEfsYArEb2KKQ=w2400" alt="">
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        swiperOptions: {
          slidesPerView: 3,
          centeredSlides: true,
          spaceBetween: 10,
          slideToClickedSlide: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            slideChangeTransitionEnd: () => {
              this.clickSchuedule(this.$refs.Swiper.$swiper.activeIndex);
            },
          },
          breakpoints: {
            992: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          },
        },
        now: 0,
        active: 0,
        schueduleContent: [
          {
            'title': '線上說明會 2021.7.31',
            'content': `為配合防疫，將於 2021.7.31 舉行線上說明會。<br />
            歡迎全國大專院校和碩士班學生參與，一同暸解如何參與第六屆台積電青年築夢計畫！`,
            'img': 'https://lh3.googleusercontent.com/jXxKsnU-Eftkc3sc3YHaGZMgh231znP3VM6qwPeFLy4FBiUhPTUPcYJPgxc7AN4D2pbX2wj_cd0aUecmMD0CF8XbuA0YaeUh8uCgCwYY_R0_eSj6K0I7Mn9Ao8ctCZSGL0J32EKc1A=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/w2rXnZriHseNvCc-5CGoFCh1d9NZWQ2QdZQHxp3Wgr4jR8pdFS82dvrMCxXO68ncn77nwIs8NjcsrJUbbk_zHS4DTWweYdpMducmZ7UURoBMnSx6gsej6SQP660OZpzIkfiNsVW7qg=w2400',
          },
          {
            'title': '徵件開始 2021.8.1',
            'content': `你是學生嗎？你有夢想嗎？<br />
              台積電青年築夢計畫助你一臂之力！<br />
              歡迎全國各地就讀大專院校學子(含碩士班)組隊報名參加<br />
              如果你有夢想渴望實踐，右上角的報名專區用力點下去！`,
            'img': 'https://lh3.googleusercontent.com/_PtTAoZRiu5Klz4KC7R1_GX2ABl8pNIShwaM-XMwzKB3BdlSvZTPPOxqNfuNAMa3LNkvK_LhdLY9-eBgd2Yw_M-bGZYdSKmFaaWwjCpeZa_KPG3_UR3VFo0I9XyPrhgLT-vaNYnzTg=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/94ZGS28LWwUBsxxRjjTjwOaLRpmazciMeY51XwzK_KWzO_XN7BeiU8GlRrIlqXNys4OOT5LlAQrozZAruJia7_YyLm5aUnYD_lv4VmY86w3NPdk6Kau7UzoOcF27h96Md-GvWO3rfA=w2400',
          },
          {
            'title': '跨界對談 2021.9.7',
            'content': '跨界對談特別邀請台積電文教基金會執行長許峻郎、JUST IN XX 設計師周裕穎、林事務所執行長林承毅及台大創新學院創新長劉建成齊聚，分享永續議題結合實務等親身經驗。現場名額有限，歡迎有興趣同學報名參與！',
            'img': 'https://lh3.googleusercontent.com/9b6giB60_4p-WyldlL7wchoe6tkE08uRW5ZtyB0Nbwor8mXzb0O2K56BR7wk-_Hy4juvfjY-e9J22yxk_CoUhEQ_telvclc1gNCKsEZp1G2u9BLMQ8S-G-yr-2JdvET03dJku-Jp5Q=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/YZtcpsd3WNWbmd2RQSD6ORmBjenMBJY4b0eUmX5DkBnEYjWKSwq059YHavT5dv1gxo6zta2AZwpqBHumYluSiu-39TOC7dvQPl6JimQu1vDXt7mQ6M4UCJ0pCK0u9MrqeCaWoQmYkQ=w2400',
          },
          {
            'title': '築夢工作坊 2021.9月',
            'content': `築夢的道路上，讓我們助你一臂之力！<br />
              是否對於永續議題有更深層認識的渴望？是否對於企劃發想仍不熟悉？是否對於呈現完美簡報仍沒把握？讓我們來協助你！<br />
              第六屆台積電青年築夢計畫舉辦逐夢工作坊，邀請六位專業講師團隊，透過課程提升各團隊參與築夢計畫的必備技能，讓你的夢想得以實踐！<br />
              `,
            'img': 'https://lh3.googleusercontent.com/V6BatSqtgCdkLABNcwa7WiGMnDTdtz2suOYh7sAq7y6wLbD1dO1uv-tkapcoeUcQnXG3qnIeO4Bh_KnvR5F-nMe8EWWF8aT-TIiINM8_znBgn2v40wYJfOYYe-oTLpYW_YPMiyb6gA=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/5BygR0RHoQbUhkse8DgvDSH0sx13gWih74Rvlb4ZmbPjJ7TAMQYlh5Sx8qkWRA4xqNOFVgiCbqUC_4XrMs2TvkUaW9KqbHnKr16RgaobSj9P4Mb4HGUgQEjxp-aAFtcdJ0P9VEccXw=w2400',
          },
          {
            'title': '徵件截止  2021.9.30',
            'content': `徵件時間到 2021.9.30 中午 12 點截止<br />
              逾期恕不受理`,
            'img': 'https://lh3.googleusercontent.com/68ShW0-8OziaqWtvOqSTnRzMEsvvEkNO48shTCyXlRivd-BdyH9D2VrxgmYapiA4a4yBm9Ql5GmXge35UdzD2dXER9hKrPBGMmNoKS5oxblrN7M-fEGIqAhGZiJ1Gq0NGg0Xbc7PFA=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/AOVtiFOy9p04mX9n0g1c1RsPKiiI96d3Yoo-556ai2GVMAy3KTSump8plOldOQNE0xhg9tTRFqPPT1p48ucF9vEIAdyl4lvRkJDwBO2Hc9sH8edCl7muR94xL-h0SOWY8aPc4ntk8A=w2400',
          },
          {
            'title': '公佈初審結果 2021.10月',
            'content': `由團隊提供 2 頁 A4 計畫大綱<br />
              評審將透過<br />
              * 創意<br />
              * 可行性<br />
              * 影響力<br />
              以上三個面向進行評分`,
            'img': 'https://lh3.googleusercontent.com/-4fnNwbwY2Shhr4QT8OyJwk2n_4u0_bz5AWuMkvAHcJVSeruD97fLXVLLfIfG9lO7NiLHDZ4F1yf-jMVh8STkBL-TmZId7h_Iu_vrOAITA-qcUXxWB-26NAIxsZfEtwcPlkCgrAnwQ=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/jIAn5oXgSmtLOELKQldIdjFUNWycGvv0_Mc7WtqleCUCANKhPOPext8FuRJf5TuAWUJJsEErHtUyNvqVDcpzAOvRpp3p4dwrQAgzgNYL9t4r6y00JG-Nvq8cVB_9HkHm1QkLYyLilg=w2400',
          },
          {
            'title': '複審收件截止 2021.11月',
            'content': `入圍複審隊伍需提供 10 頁內之正式書面企劃
              以 PDF 格式上傳至指定電子信箱 tsmcudreamer@atona.com`,
            'img': 'https://lh3.googleusercontent.com/WXX33_kpQNT59E1LGX4U5QSPzPffyuN9ktpbiJVP0oCz2AgAfcbGP3c6NOvky1rUcgK6Q1VZPhUOYc2RuSiPhTnAr2pHwk6HDtHcgacSdPXKp5Be3B9QDQUaax82lITQe_CyAxQABg=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/TSbFarpmmEYZuj40Se-h_cimGHNUEfirb79-6SNxSXD26-wPDsHPrTwqRV9JU3BF6ytf0gyd_O7-ucg4ihxh_8nHzs32X5JBaKgfXJqgvpeqI1UTB47NIbiKFE8um-vywPxL2PVS8w=w2400',
          },
          {
            'title': '公佈複審結果 2021.11月',
            'content': `評審將透過<br />
              * 執行時程<br />
              * 預期成效與影響力<br />
              * 宣傳與預算規劃<br />
              以上三個面向進行評分`,
            'img': 'https://lh3.googleusercontent.com/ZbnIWyHiM0qE9PRH8q0m5Ae1b0YmxmGij1Cckj7FMBQmxuEpI4fM7OFLOxOMFzPt_nEOHIIQwYiyixP8eFU-rgKd3xceNUzOP5fnKhDp77itg7H8pFdadwvHKyiP_6fbvlSx2k_T9w=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/39rXx6xtlwrWzsGBWv4H9EjUk9ulBfwIIKq-SkkgwgY4oJjZCTf4rljajyJ5lyi8zHYbzFFhi-2Tz7j6AMrTVujh9VJPEXLQ_azo_tdfYFOhEmdE7QWRKpFv00gDhariMmaTCPEVNw=w2400',
          },
          {
            'title': '強化工作坊及企業參訪 2021.11月',
            'content': '針對入選團隊的進階課程，協助團隊將企劃完善並協助同學解答團隊問題。除了強化工作坊外，也邀請所有入選團隊前往台積電參訪，第一手學習台積電在環境永續上的努力作為。',
            'img': 'https://lh3.googleusercontent.com/l4BzaAmpHPvih_TtqirQZ2NkOplt7SLMjsZdqWLR5uojRxlQ5y7Bcnh3vZNwJlfxLj1SeTou7bcX18Sgd7NlP5IyBdm_FX-nvZzUeH5ZGd5KurufRov1EBW3iMlc7d3twOnUSv_kQA=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/ZBHUusxDHUv6iZ-MnOxXeiZqJ0mwimPBXKKjiWH667Qdx72pstf2yqmiV9p5g2qEvYZDkZrv9PpIetsWA-4aCeAaX1-zsA7vDr6MDOTfFp7EBCXsY4GFcLGmg6mNg-1D6Mm2oomtEQ=w2400',
          },
          {
            'title': '決審 2021.12月',
            'content': `入圍決審隊伍必須以口說形式進行 7 分鐘發表<br />
              並由評審發問評分。<br />
              評審將透過<br />
              * 執行時程<br />
              * 團隊心理素質與抗壓性 <br />
              * 預期成效與影響力<br />
              * 宣傳與預算規劃<br />
              以上四個面向進行評分`,
            'img': 'https://lh3.googleusercontent.com/_R1D_tm9sIy4ifuJXUE0JKnulZi8gPwYUXJzpUlDVZHfJdHaGTy5hiUiyd-XXbOaX-7uv_-xj2q62qn4Q9dsX7IPSdpA9BrDnI_IOU090QadTmm10EcgRT1I7BfWPm7pA-wyTY_oVQ=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/JmngFwzNdF2jFWarvcCN7FM3e6vuQ_paIUa3lV_6gky07szrtr5KCaDGjlpYTDoYgpxjP21_dUQ4ib4uWjOo150Gk_Uv47oGbBW6ZkQZbstF6VekFZQCWWDuGRzin0s5TZEGP4Nr1g=w2400',
          },
          {
            'title': '入選團隊面談 2021.12月',
            'content': `獲補助團隊與主辦單位、評審老師面談<br />
              分享期初的執行進度`,
            'img': 'https://lh3.googleusercontent.com/JuSLU2Bpz7-4CkWOZ0TbqmyNIm0ssGMD4A4SqmioNDsfItW7elEftWdZTO1zg3eQ9s46h3tnj0ZwBuLCUIBi0J1wE1nYAys7JzUM6tTJsbWPjfYQik8DywNuakwqMmsBxDclb7veHg=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/Dor0IFg9ZNRuNgob4IpbQN4dRx6XixHpVuFlD1syvoBnjMt-j5eE1XB8klk-ZwNG_-_QyS0q6m26ErYkoeIkWYWZ3-TIBQ1AY-YL3vufKcN-ROOfcI8MPijWkiRsnAqsFdojRA_jOA=w2400',
          },
          {
            'title': '期中分享會 2022.4月',
            'content': `入圍隊伍需提供期中報告 PPT 檔案<br />
              並參與口頭期中分享會，報告執行進度`,
            'img': 'https://lh3.googleusercontent.com/M3lbpx6ZWQnKKhyytz2Y5ycqHx54loxl1inlekduFblGctrL7YU1v_v8MuVI83Ybp14w6i8zrAzon3zNV3c7tyX077vbPytMfKYE6_N0uRbSMBLH_BKHLGYykVGKHuqMIAnhmCxKRQ=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/6HmYcpkuu1OTnNboByP9E-o3h8cNua_81_P5oO96bxKTG5Y7JDx1CZ7J6HL_Ih5Cei6S8JMoBuzLfiJBpFlwF_J4VwoDm9pDzHAq_cjA3-nTDE0aDk48bIPLRS8zmP7xQhUzYKqjjA=w2400',
          },
          {
            'title': '成果發表會 2022.9月',
            'content': `獲補助之隊伍需提供以下資料<br />
              * 成果 PPT 檔案<br />
              * 專案計畫之臉書粉絲專頁獲得 500 個以上的「讚」<br />
              * 1 分鐘成果精華影片及 3 分鐘成果完整影片各一支成果影片（規格 1080P 以上，含中 英文字幕）內容包括規劃期、執行期、成果展現三部分`,
            'img': 'https://lh3.googleusercontent.com/0ykcIRwsDVs29kY5sHG3pQgtNw8glheXv40pSzz0sHKn_9X_gfKoQ-yWBCBBJLhzbNViO0h72DQfd82qE37Q-H_hwKXohigY960jglES6zabzlVS2yUcvyAKA4pKTYC4wVIajfa_uw=w2400',
            'activeImg': 'https://lh3.googleusercontent.com/fUtCy0m7QRXkOeVpzuWW9C1gqBJ7W9PARs5QwRhbku6bJ047h8lAC-L1zwcd201fTrhzbT1n8U2M52g_4Rcj_Zzb8nMU81QY9Hv_f0pLx2FdvA1V-2dAp16KMxGnyLRVXWXhYPsgwA=w2400',
          },
        ]
      }
    },
    methods: {
      clickSchuedule(key) {
        this.now = key;
        this.active = key;
      }
    },
  }
</script>

<style lang="scss" scoped>

  .item_container {
    background: url('https://lh3.googleusercontent.com/p1qhEl7iZmeyOiOQkzA4BP3njx9rcWbw8-rvCVU-6bcVhCbxUqUCHCUpZHsL3PRu6pfQmscXDMeT0hyPkda85mCkgMWenzo0OAL_sgAD_ZLRPEC859fficISCbujdRuxyNakAoDiMA=w2400');
    @apply relative flex-col;

    .item_wrapper {
      @apply mx-auto;

      .item_content {
        @apply flex flex-col justify-around;

        .item {
        }
      }
    }
  }

  .description {
    @apply w-4/5 lg:w-3/5 my-4 mx-auto text-left;
    
    h3, p {
      @apply m-6;
    }
  }

  .img_date {
    @apply cursor-pointer;
  }

  hr {
    @apply w-full;
  }

  .schedule_ground {
    @apply hidden lg:block;
  }

  .schedule_ground_m {
    @apply block lg:hidden;
  }

  .schedule_woman {
    @apply hidden lg:block lg:absolute w-1/12 left-27% bottom-11% origin-bottom z-10 animate-woman_watering;
  }

  .schedule_man {
    @apply hidden lg:block lg:absolute w-1/12 left-34% bottom-9% z-10;
  }

  .schedule_stall {
    right: 34%;
    bottom: 3%;
    @apply hidden lg:block lg:absolute w-1/12 right-34% bottom-3% z-10;
  }

  .schedule_man2 {
    @apply hidden lg:block lg:absolute w-1/12 right-25% bottom-3% z-10 animate-man2_move;
  }
  
</style>
