<template>
  <div class="dropdown_nav item_container">

    <transition name=fade>
      <div class="mask"
        v-show="menuShow"></div>
    </transition>
    
    <div class="nav_bar">
      <div class="item_wrapper">
        <div class="img_logo">
          <router-link class="link"
          to="/"
          >
          <img class="logo_udreamer"
            src="https://lh3.googleusercontent.com/iVqSw-yZA7s6HIkICkxElflOnxqAuvJL3-4SpOJjaS8H4zjadeMGayb4vNLj8GsVa78FfR9dzv53m718iYAPsA0Z2Y6Fzc8VKOoYLX29O2Zv4RNcK-7Ld0se46LQ6ZbxGdVPOYoCCQ=w2400" alt=""
            @click="scroll('kv')"
            />
        </router-link>
        </div>

        <button class="hamburger hamburger--collapse" :class="{ 'is-active': menuShow }"
          type="button"
          @click="menuToggle()"
          >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>

      <div class="menu"
        v-show="menuShow"
        >
        <div class="item menu_col"
          @click="scroll('kv')"
          >
          <a class="link">計畫緣起</a>
        </div>
        <div class="item menu_col"
          @click="scroll('news')"
          >
          <a class="link">最新消息</a>
        </div>
        <div class="item menu_col"
          @click="scroll('schedule')"
          >
          <a class="link">計畫期程</a>
        </div>
        <div class="item menu_col"
          @click="scroll('seminar')"
          >
          <a class="link">校園說明會</a>
        </div>
        <div class="item menu_col"
          @click="scroll('workshop')"
          >
          <a class="link">築夢工作坊</a>
        </div>
        <!-- <div class="item menu_col"
          @click="scroll('enhance')"
          >
          <a class="link">強化工作坊</a>
        </div> -->
        <div class="item menu_col"
          @click="scroll('qa')"
          >
          <a class="link">常見問題</a>
        </div>
        <div class="item menu_col"
          @click="scroll('contact')"
          >
          <a class="link">聯絡方式</a>
        </div>

        <router-link class="item menu_red"
          to="/register"
          >
          <a class="link">報名專區</a>
        </router-link>

        <div
          class="item menu_green"

          >
          <a
            class="link"
            href="https://tsmc-udreamer.org/review"
            >歷屆回顧</a>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
  export default {
    data() {
      return {
        menuShow: false,
      }
    },
    methods: {
      menuToggle() {
        let vm = this;
        vm.menuShow = !vm.menuShow;
      },
      async scroll(target) {
        let vm = this;
        vm.menuShow = false;
        if(this.$route.path !== '/') {
          await this.$router.push('/')
        }
        let topOfElement = document.querySelector('#' + target).offsetTop - 72;
        window.scrollTo({top: topOfElement, behavior: 'smooth'});
      },
    },
    watch: {
      menuShow() {
        if (this.menuShow) { 
          document.documentElement.style.overflow = "hidden";
          document.documentElement.style.webkitOverflowScrolling = "hidden";
        }
        else {
          document.documentElement.style.overflow = "auto";
          document.documentElement.style.webkitOverflowScrolling = "auto";
        };
      },
    },
    mounted() {
      // mobile safari 100vh
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  }
</script>

<style lang="scss" scoped>

  .item_container {
    
    .item_wrapper {
      @apply w-11/12 flex-row p-0 m-auto;
    }

    .logo {
      @apply w-24;
    }

    .nav_bar {
      background: url('https://lh3.googleusercontent.com/3cxLSZG1qdkqM8QZwGFM1ls85AWj5oh_ys25J9lHTrcN-Lxflr3rnmVYKr23kl729FAI8PDkVQsj6eLD-y3UhONUxUQ3YyOntpCzhro_7EPX4VE9odfqN16LC3aW2zgEpPPX-B8Ykg=w2400');
      @apply flex lg:hidden fixed w-95 h-16 flex-col left-1/2 top-2 transform -translate-x-1/2 shadow-bottom z-50;
    }
  }

  .hamburger {
    @apply cursor-pointer;
  }

  .menu {
    height: calc(var(--vh, 1vh) * 100 - 5rem);
    background: url('https://lh3.googleusercontent.com/3cxLSZG1qdkqM8QZwGFM1ls85AWj5oh_ys25J9lHTrcN-Lxflr3rnmVYKr23kl729FAI8PDkVQsj6eLD-y3UhONUxUQ3YyOntpCzhro_7EPX4VE9odfqN16LC3aW2zgEpPPX-B8Ykg=w2400');
    @apply flex-col #{!important};
    @apply fixed flex w-full justify-around items-center top-16 z-20 overflow-auto;

    .item {
      @apply w-full h-12.5% flex justify-center items-center cursor-pointer;

      .link {
        @apply mr-2/5 font-semibold;
      }
    }

    .menu_col {
      @apply border-line_gray border-b;
    }

    .menu_red {
      @apply bg-menu_red text-white;
    }

    .menu_green {
      @apply bg-menu_green text-white;
    }
  }

  .img_logo {
    @apply self-center;
  }

  .logo_udreamer {
    @apply  w-32 mr-4;
  }

  .mask {
    @apply absolute w-screen h-10screen top-0 bg-gray-800 bg-opacity-70 z-10;
  }
  
  .btn_red {
    @apply self-center text-center px-4 py-1 bg-btn_red text-white rounded-full border border-black focus:outline-none filter hover:brightness-90;
  }

  .btn_green {
    @apply self-center text-center  px-4 py-1 bg-btn_green text-white rounded-full border border-black focus:outline-none filter hover:brightness-90;
  }

</style>
