<template>
  <div
    id="news"
    class="news item_container"
    >
    <div class="img_wrapper">

      <div
        class="back_top item_wrapper"
        ref="news"
        >
        <h2
          class="sub_title"
          data-aos="fade"
          data-aos-duration="2000"
          data-aos-delay="300"
          data-aos-anchor-placement="bottom-bottom"
          >
          <img
            class="icon_title"
            src="https://lh3.googleusercontent.com/OoGYO7GWJuCfPDqTDgh1CpLsAvkOKEHydAisWzGscZPSXtlY28m27glV1VKervTSCWSakO2NjxCLmxjbhSE3fhq8pH2Bkc8fHsPvWUud2RFU2QTSNNN6Ot-tbxd8rQKX_V88ZgclFQ=w2400"
            data-aos="zoom-in"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
          最新消息
        </h2>
        <div class="line">
          <img
            src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
        </div>
        <div class="item_content">

          <template v-for="(item, key) in reverseNews.slice((nowPage - 1) * 5, nowPage * 5)">
            <div class="item"
              :key="key"
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
              <p class="news_date">
                <img
                  class="img_new"
                  src="https://lh3.googleusercontent.com/UkOL4ioEMuHMd3nQPyUTfoXgYwQqTUHG-vdbA1d-jwpgwHXSJLeErSqOZjB0FH3fSHmaWVNwsDP_8-eZO1IYoDj8LBf6u9WTnUhcnYAgH8FEScmnuNNPLhFDuvAY5CPvgxjfYxmO3g=w2400"
                  v-if="key === 0">
              </p>

              <div class="news_bg" :class="{ 'bg_mask': item.show }"
                @click="newsToggle(key)"
                >

                <transition-group name="fade" tag="div"
                  :key="'t' + key">
                  <p class="news_title" :class="{ 'txt_ellipsis': !item.show }"
                    :key="'title' + key">
                    {{ item.title }}
                  </p>
                  <div class="news_content"
                    v-show="item.show"
                    :key="'list' + key"
                    >
                    <hr>
                    <p v-html="item.content"></p>
                  </div>
                </transition-group>

              </div>

            </div>
          </template>

        </div>
      </div>


<!-- 有扇 -->
      <!-- <div class="item news_ground">
        <img src="@/assets/image/bg/bg-9-1.png" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
          >
      </div> -->

<!-- 無扇 -->
      <div class="item news_ground">
        <img
          src="https://lh3.googleusercontent.com/usW9egB8QPo1dOPRumkENYCZHAZxVtMYEMwEh6PyTm0P9U69VNzQkkF04K7feDu_kb5wxKG_Mqsekfaoi5WjLjb5Sv1f6OkaHbhzma8SNdeDUmSRQzCo6GzQt3cJBo8CBcUWgEW7Dg=w2400"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
          >
      </div>




      <div class="item news_ground_m">
        <img src="https://lh3.googleusercontent.com/ZDA_OZChCu-LelsGbwVjTrLD-TI9L21ioTY_oOVNWbZwIWyMoxLxsj9rPESdV7Zj8RX2TJYmLwnDoASq7X29ew7ebb_H-3abwQhuS92xT7uW7eHuS0PFOdj_VuI82DVIrlRe8cYb_g=w2400">
      </div>


<!-- 扇 -->
    <div class="item img_pole_1">
      <img src="https://lh3.googleusercontent.com/6eJESkpZa_2S1C_UJWwqOfNAZjn4JbTAMhgLZfb_ZPwNteY6ZT9I05zbJpQB8Po43cp-8uysABdtylJNInmkvhNFMN9zwm-ZRVOQI-stgNZArokSBerfeWsDlTU1D4a70SgYC3ZdCg=w2400" alt=""
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-anchor-placement="center-bottom"
        >
    </div>
    <div class="item img_fan_1">
      <img src="https://lh3.googleusercontent.com/5KcCPQ1gNN3R6twYyBQyeCltL0y_gdNgdNzFAFXnj9L7tBHm8T6p12fqZvzP962d-0vBM7IItu_wNeVEsIAiht30C3EVZdRg4R0bDi1Ef37yrAQZ3hzZ6-rdEaJHoUJLPmRBd61mIQ=s172-p-k" alt=""
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-anchor-placement="center-bottom"
        >
    </div>
    <div class="item img_pole_2">
      <img src="https://lh3.googleusercontent.com/6eJESkpZa_2S1C_UJWwqOfNAZjn4JbTAMhgLZfb_ZPwNteY6ZT9I05zbJpQB8Po43cp-8uysABdtylJNInmkvhNFMN9zwm-ZRVOQI-stgNZArokSBerfeWsDlTU1D4a70SgYC3ZdCg=w2400" alt=""
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-anchor-placement="center-bottom"
        >
    </div>
    <div class="item img_fan_2">
      <img src="https://lh3.googleusercontent.com/5KcCPQ1gNN3R6twYyBQyeCltL0y_gdNgdNzFAFXnj9L7tBHm8T6p12fqZvzP962d-0vBM7IItu_wNeVEsIAiht30C3EVZdRg4R0bDi1Ef37yrAQZ3hzZ6-rdEaJHoUJLPmRBd61mIQ=s172-p-k" alt=""
        data-aos="fade"
        data-aos-duration="1000"
        data-aos-anchor-placement="center-bottom"
        >
    </div>






      <div class="img_meet">
        <img src="https://lh3.googleusercontent.com/ofjnEV7OsJSDjz73iyKLxJM9zF8_KJHINZRih_d29kUKK-yUY8UPj64PEh8QmrrkNjvbkXw-FTEzaMPMhp0RCReD2Cf6bv0fT-OXrjZSn5wbygVjp0YmveSbQe7GmFR22fQA5_NrzA=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="img_bulb">
        <img src="https://lh3.googleusercontent.com/zJLiluMTx_kFP8jt5PuaVQbJsXRnECdQi4GkX1zPisiz_FBKWc96rux-ZIlxEbSiQMpgBcqfykYdXX2SmPFjEL4HTGeG0ZDB9rQS22HiArZ1SrS39-Tc2cv6Zt4OJA7wUmt207_-tw=w2400" alt=""
          data-aos="zoom-in"
          data-aos-offset="150"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        nowPage: 1,
        news: [
          {
            show: 0,
            date: '7.1',
            title: '7/31（六）歡迎參與第六屆台積電青年築夢計畫-線上說明會！',
            content: `為配合防疫，將於 2021.7.31 舉行線上說明會。<br />
              歡迎全國大專院校和碩士班學生參與，一同暸解如何參與第六屆台積電青年築夢計畫！<br />
              <br />
              • 活動對象 : 全國大專院校和碩士學生皆可報名參加<br />
              • 活動時間 : 2021/7/31 (六) 14:00~16:00<br />
              • 直播平台 : 台積電青年築夢計畫 臉書粉絲專頁<br />
            `,
          },
          {
            show: 0,
            date: '8.31',
            title: '9/7 (二) 台北場校園說明會取消',
            content: `感謝大家對第六屆台積電青年築夢計畫一直以來的關心與詢問<br />
            因受疫情影響，原訂於 9/7 (二) 舉辦的台北場校園說明會確定取消<br />
            歡迎同學前往青年築夢計畫臉書粉絲團收看「線上說明會」完整直播影片<br /><br />
            線上說明會完整直播影片 ☞ <a href="https://reurl.cc/2r5K14" style="color:blue; text-decoration:underline;" target="_blank">點我觀看</a>
            `,
          },
          {
            show: 0,
            date: '9.1',
            title: '9/7 (二) 台積電青年築夢計畫-跨界對談',
            content: `想對 SDGs 永續議題了解更多嗎？<br />
              好奇永續議題要如何結合實務嗎？<br />
              超精彩的跨界對談，報名正式啟動<br />
              <br />
              因爲疫情現場的名額有限，因此本次跨界對談全程會在粉絲專頁上以線上直播的方式分享<br />
              <br />
              【🔎 #跨界對談報名資訊】<br />
              主題：跨界攜手，邀全國學子一起打造永續未來<br />
              時間：9月7日 (二) 11:00<br />
              地點：孫運璿科技人文紀念館 B1講堂（台北市中正區重慶南路二段6巷10號）<br />
              主持人：台大創新學院 劉建成 創新長<br />
              與談人：台積電文教基金會 許峻郎 執行長<br />
              　　　　林事務所 林承毅 創辦人<br />
              　　　　JUST IN XX 周裕穎 設計師<br />
              <br />
              即刻報名，掌握永續趨勢 👉 <a style="color:blue; text-decoration:underline;" target="_blank" href="https://lihi1.cc/8ljGM">點我報名</a>
            `,
          },
          {
            show: 0,
            date: '9.25',
            title: '9/25 (六)【2021 台電青年築夢計畫】#築夢工作坊 開放報名',
            content: `關於夢想，你準備好實踐了嗎？<br>
              17 項永續目標 x 8 位築夢講師 x tsmc 浩大陣容！<br>
              <br>
              #築夢工作坊 8為築夢講師<br>
              帶你用不同角度切入永續議題<br>
              跟你一起討論你的永續夢想<br>
              一起建築我們的永續未來<br>
              <br>
              時間：9/25 (六) 9:00-18:00<br>
              地點：集思交通部國際會議中心 集會堂(台北市杭州南路一段24號)<br>
              <br>
              築夢的道路上，讓我們助你一臂之力！💪<br>
              名額有限，馬上報名 築夢工作坊：<a style="color:blue; text-decoration:underline;" href="https://reurl.cc/DZ1x8e" target="_blank">點我報名</a><br>
            `,
          },
          {
            show: 0,
            date: '9.30',
            title: '9/30 (四) 2020 第五屆台積電青年築夢計畫-成果發表會，歡迎同學到場觀摩 !',
            content: `2020 第五屆台積電青年築夢計畫獲選8組團隊，包括清華、中央、中興、聯合、逢甲、明新、長庚等大學同學，分別以全英語Podcast、醫學知識結合社群宣傳、廢棄行道樹再利用、解決農業廢棄物、打造傳統泰雅食農教育園區、3D列印流浪動物義肢輔具、翻轉缺角世界的城市教育發芽計畫以及紀錄片傳達台灣運動員面臨的問題等進行為期一年的夢想計畫，將於 9/30 向大眾展示成果。<br />
            <br />
            時間：2021/09/30 13:00-16:30<br />
            地點：新竹清華大學孫運璿演講廳 (新竹市光復路二段 101)<br />
            活動免費入場，歡迎有興趣參加「2021 第六屆台積電青年築夢計畫」的同學至現場觀摩學習。
            `,
          },
          {
            show: 0,
            date: '10.20',
            title: '10/20 (三) 初審入選團隊名單公布',
            content: `
            <p>初審入選團隊名單 (依筆畫順序排序)：</p>
            <table cellpadding="6" cellspacing="0" style="border:1px solid #333;margin-top: 1rem">
              <tbody class="news_table">
                <tr>
                  <td>21 天卡西諾</td>
                  <td>AgriCulture</td>
                  <td>Ibu原鄉共伴屋</td>
                  <td>KCSY魚丸</td>
                  <td>LAPSEE</td>
                </tr>
                <tr>
                  <td>Listener</td>
                  <td>MentorList 夢想青年</td>
                  <td>PAINgo鯪</td>
                  <td>Revery夢想團隊</td>
                  <td>UndergroundStudio</td>
                </tr>
                <tr>
                  <td>WackO</td>
                  <td>We Can Change</td>
                  <td>一個司康</td>
                  <td>人造亞特蘭提斯</td>
                  <td>手把手科技有限公司</td>
                </tr>
                <tr>
                  <td>外來終結者-綠廚人</td>
                  <td>企桃囡仔</td>
                  <td>汝廁</td>
                  <td>艾登星Hudence生科有限公司</td>
                  <td>吾居無塑</td>
                </tr>
                <tr>
                  <td>杯特</td>
                  <td>河東獅吼</td>
                  <td>島島青年</td>
                  <td>海游生物</td>
                  <td>移動綠洲</td>
                </tr>
                <tr>
                  <td>尋星⼈⾏動實驗室</td>
                  <td>循環台灣 活用絲瓜</td>
                  <td>程堡</td>
                  <td>酸甜苦辣</td>
                  <td>蝶釀永續有限公司</td>
                </tr>
                <tr>
                  <td>獨立書店不DO書</td>
                  <td>點燃設計</td>
                  <td>爆學力 Atom SkooL</td>
                  <td>藝起Yi-Chi</td>
                  <td>歡迎太陽來點燈</td>
                </tr>
              </tbody>
            </table>
            <style>
              table tbody tr td{
                border: 2px solid #333;
              }
            </style>
            `,
          },
          {
            show: 0,
            date: '11.18',
            title: '11/18（四）複審入選團隊名單公布',
            content: `<p>複審入選團隊名單 (依筆畫順序排序)：</p>
            <table cellpadding="6" cellspacing="0" style="border:1px solid #333;margin-top: 1rem">
              <tbody class="news_table">
                <tr>
                  <td>21 天卡西諾</td>
                  <td>AgriCulture</td>
                  <td>Ibu原鄉共伴屋</td>
                  <td>UndergroundStudio</td>
                  <td>WackO</td>
                </tr>
                <tr>
                  <td>一個司康</td>
                  <td>企桃囡仔</td>
                  <td>吾居無塑</td>
                  <td>杯特</td>
                  <td>島島青年</td>
                </tr>
                <tr>
                  <td>移動綠洲</td>
                  <td>尋星⼈⾏動實驗室</td>
                  <td>循環台灣 活用絲瓜</td>
                  <td>獨立書店不DO書</td>
                  <td>歡迎太陽來點燈</td>
                </tr>
              </tbody>
            </table>
            `,
          },
          {
            show: 0,
            date: '12.7',
            title: '12/7（二）獲選團隊名單公布',
            content: `<p>獲選團隊名單（按筆畫順序）</p>
            <table cellpadding="6" cellspacing="0" style="border:1px solid #333;margin-top: 1rem">
              <tbody class="news_table">
                <tr>
                  <td>21 天卡西諾</td>
                  <td>AgriCulture</td>
                  <td>Ibu原鄉共伴屋</td>
                  <td>UndergroundStudio</td>
                </tr>
                <tr>
                  <td>企桃囡仔</td>
                  <td>島島青年</td>
                  <td>移動綠洲</td>
                  <td>尋星⼈⾏動實驗室</td>
                </tr>
                <tr>
                  <td>循環台灣 活用絲瓜</td>
                  <td>獨立書店不DO書</td>
                  <td>歡迎太陽來點燈</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <br>
            <p>恭喜以上獲選團隊，接下來將會與各團隊聯繫，也請團隊注意收信！</p>
            `,
          },
          {
            show: 0,
            date: '4.23',
            title: '4/23（六）2021 第六屆青年築夢計畫 期中分享',
            content: `
            本次期中分享會邀請 11 組入選團隊到場分享執行成果，並邀請六位經驗豐富指導老師給予團隊最寶貴的築夢建議！<br>
            <br>
            業師名單（依所屬單位筆畫順序）<br>
            天下雜誌   鄭宜媛 執行董事<br>
            台大創新設計學院 劉建成 創新長<br>
            台積公司企業公共關係部 高孟華 副處長<br>
            台積電文教基金會 邢智田 董 事<br>
            台積電文教基金會 彭宗平 董事<br>
            陽明交通大學 陳一平 教授<br>
            <br>
            因疫情影響，本次分享會僅邀請團隊出席，歡迎有興趣觀摩的同學參與將於九月舉行的成果分享會！
            `,
          },
        ],
      }
    },
    computed: {
      reverseNews() {
        const vm = this;
        return vm.news.reverse();
      },
    },
    methods: {
      newsToggle(t) {
        let vm = this;
        vm.news[t].show = !vm.news[t].show;
      },
    },
  }
</script>

<style lang="scss" scoped>

  .item_container {
    background: url('https://lh3.googleusercontent.com/8na-WXjzIBtC6fVlzr1FH8TnvIVauVoUpWapGgxb5wLqEg3YeGODEvl4va8B3e5C3RIR1aO3oFPI8y9EUKPJhoeyAQscJxV3MKKF9jCm8kbneJnFSaoafxb1-jb_BgS0nsQKKZ64vQ=w2400');
    @apply relative flex-col;

    .item_wrapper {

      .item {
        @apply flex justify-center items-start w-4/5 lg:w-11/12 my-4 mx-auto;

        span, img {
          @apply max-w-none;
        }
      }
    }
  }

  .img_new {
    @apply hidden lg:block w-full;
  }

  .news_date {
    @apply hidden md:flex w-1/5 justify-start items-center;

    img {
      @apply w-1/2 m-auto;
    }
  }

  .news_bg {
    @apply w-full md:w-4/5 self-center text-left mx-auto rounded-b-xl transition-all duration-300 ease-linear cursor-pointer;

    .news_title {
      @apply font-semibold self-center;
    }

    .news_content {

      &>p {
        @apply mt-4;
      }
    }
  }

  .bg_mask {
    @apply p-4 bg-mask rounded-2xl overflow-auto;
  }

  .txt_ellipsis {
    text-underline-position: under;
    text-underline-offset: 3px;
    @apply overflow-hidden overflow-ellipsis whitespace-nowrap underline;
  }


  hr {
    @apply w-full;
  }

  .img_meet {
    @apply hidden lg:block lg:absolute w-18% lg:-bottom-1%;
  }

  .img_bulb {
    @apply hidden lg:block lg:absolute w-4% lg:bottom-21% lg:right-41% origin-bottom-left animate-bulb_shake;
  }
  
  .news_ground {
    @apply hidden lg:block mt-16;
  }

  .news_ground_m {
    @apply lg:hidden;
  }





.img_pole_1 {
  width: 24px;
    bottom: 124px;
    left: 174px;
    @apply hidden lg:block lg:absolute;
  }
  .img_fan_1 {
    width: 118px;
    bottom: 178px;
    left: 126px;
    @apply hidden lg:block lg:absolute;

    img {
      animation: fnas 20s linear infinite;
    }
  }
  .img_pole_2 {
    width: 32px;
    bottom: 65px;
    left: 89px;
    @apply hidden lg:block lg:absolute;
  }
  .img_fan_2 {
    bottom: 132px;
    left: 16px;
    @apply hidden lg:block lg:absolute;

    img {
      animation: fnas 16s linear infinite;
    }
  }
  



  @keyframes fnas {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


</style>
