<template>
  <div id="app">
    <GDPR v-if="GDPRShow"/>
    <router-view />
  </div>
</template>

<script>
import GDPR from '@/components/GDPR.vue'
import Cookies  from 'js-cookie';
import { store, mutations } from '@/store/store.js'

export default {
  components: {
    GDPR
  },
  computed: {
    GDPRShow() {
      return store.GDPRShow;
    }
  },
  methods: {
    GDPRToggle: mutations.GDPRToggle,
  },
  created() {
    if(!Cookies.get('udreamer_PReg')) {
      this.GDPRToggle(true);
    } else this.GDPRToggle(false);
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss';
</style>
