<template>
  <div class="nav item_container"
    data-aos="slide-down"
    data-aos-duration="1000"
    data-aos-delay="300"
    >
    <div class="item_wrapper">

      <div class="item_row">
        <a class="link"
          href="https://www.tsmc-foundation.org/"
          target="_blank"
          >
          <img
            class="logo_tsmc"
            src="https://lh3.googleusercontent.com/xN0MZY5IV02r1Cn3yl1G4KWfTC-2ZDWI8QmPZvdldnilUEFrKdQbiEPUHA5nzEnZkwpO8fgi49iqRFNzmX_Ll1hGN747_9XXn68QBQ9fCx76U-vcFxBExJU6YcmHYc4kt90rhOqR7w=w2400"
            />
        </a>
        <router-link class="link" to="/" href="">
          <img
            class="logo_udreamer"
            src="https://lh3.googleusercontent.com/dw_AU5edcZG2n3xcPfBPPiBoolcRnKMoLx_NrSNd-9NF6FPPsrTtvLZ8HSTOa-5mw8dRrg35ME1vK5Ihaf1hdBmFkKXtUT-kP6H5CS7T6xrYQHVo-a1u4_0l4xL6gRyY4AZC6Y2aOg=w2400"
            @click="scroll('kv')"
            />
        </router-link>
      </div>

      <div class="item bottom_line">
        <a class="link"
          @click="scroll('origin')"
          >計畫緣起</a>
      </div>
      <div class="item bottom_line"
        @click="scroll('news')">
        <a class="link">最新消息</a>
      </div>
      <div class="item bottom_line"
        @click="scroll('schedule')">
        <a class="link">計畫期程</a>
      </div>
      <div class="item bottom_line"
        @click="scroll('seminar')">
        <a class="link">校園說明會</a>
      </div>


        <div class="item bottom_line"
          @click="scroll('workshop')"
          >
          <a class="link">築夢工作坊</a>
        </div>

      <!-- <div class="item dropdown">
        <a class="link">築夢工作坊</a>
        <div class="dropdown-content">
          <a class="link" @click="scroll('workshop')">築夢工作坊</a>
          <a class="link" @click="scroll('enhance')">強化工作坊</a>
        </div>
      </div> -->


      <div class="item bottom_line"
        @click="scroll('qa')">
        <a class="link">常見問題</a>
      </div>
      <div class="item bottom_line"
        @click="scroll('contact')">
        <a class="link">聯絡方式</a>
      </div>
      <div class="item">
        <router-link class="link btn_red"
          to="/register"
          >報名專區</router-link>
      </div>
      <div class="item">
        <a class="link btn_green"
          href="https://tsmc-udreamer.org/review"
          >歷屆回顧</a>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      async scroll (target) {
        if(this.$route.path !== '/') {
          await this.$router.push('/')
        }
        document.querySelector('#' + target).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      },
    },
  }
</script>

<style lang="scss" scoped>

  .item_container {
    background: url('https://lh3.googleusercontent.com/3cxLSZG1qdkqM8QZwGFM1ls85AWj5oh_ys25J9lHTrcN-Lxflr3rnmVYKr23kl729FAI8PDkVQsj6eLD-y3UhONUxUQ3YyOntpCzhro_7EPX4VE9odfqN16LC3aW2zgEpPPX-B8Ykg=w2400');
    @apply hidden fixed lg:flex h-16 shadow-bottom z-50 overflow-visible;

    .item_wrapper {
      @apply w-98 max-w-screen-xl h-full flex-row justify-between p-0 m-0 #{!important};

      .item {
        @apply flex h-full flex-col justify-center cursor-pointer;
      }

      .item_row {
        @apply h-full flex justify-center items-center;
      }
    }
  }

  .logo_tsmc {
    @apply w-12 xl:w-16 mr-4 xl:mr-6;
  }

  .logo_udreamer {
    @apply w-24 xl:w-32 xl:mr-4;
  }

  .bottom_line {
    @apply border-second hover:border-b-4;
  }

  .link {
    @apply font-bold;
  }

  .btn_red {
    @apply self-center text-center px-4 py-1 bg-btn_red rounded-full border border-black text-white focus:outline-none filter hover:brightness-90;
  }

  .btn_green {
    @apply self-center text-center  px-4 py-1 bg-btn_green rounded-full border border-black text-white focus:outline-none  filter hover:brightness-90;
  }

  .dropdown {
    @apply relative inline-block;
  }

  .dropdown-content {
    background: url('https://lh3.googleusercontent.com/p1qhEl7iZmeyOiOQkzA4BP3njx9rcWbw8-rvCVU-6bcVhCbxUqUCHCUpZHsL3PRu6pfQmscXDMeT0hyPkda85mCkgMWenzo0OAL_sgAD_ZLRPEC859fficISCbujdRuxyNakAoDiMA=w2400');
    @apply hidden absolute min-w-max top-90% z-10;
  }

  .dropdown-content a {

    @apply block px-4 py-3 no-underline hover:bg-gray-200 hover:block;
  }

  .dropdown:hover .dropdown-content {
    @apply block;
  }

</style>
