<template>
  <transition transition="pop-out">
    <modal name="consent-recommend-modal" width="400" height="auto" :adaptive="true">
      
      <div class="box">
        <div class="box-logo">
				
        </div>
        <div class="box-part">

          <div class="partition" id="partition-register">

            <div class="partition-title">確認以下事項</div>
            <div class="partition-form">
              <div class="partition_content">
                <p>未滿 20 歲者請先下載同意書，填寫簽名後上傳</p>
                <a href="/documents/2021_6th_udreamer_20consent.docx" download>未滿 20 歲參賽同意書下載</a>
              </div>

              <div  class="partition_content">
                <p>所有參賽者請先下載諮詢顧問老師同意書與推薦函，填寫簽名後上傳</p>
                <a href="/documents/2021_6th_udreamer_consultant.docx" download>顧問老師同意書與推薦函下載</a>
              </div>

              <div class="button-set">
                  <button class="large-btn cancel-btn" @click="closeModal()">確認</button>
              </div>
            </div>

          </div>
          
        </div>
      </div>

    </modal>
  </transition>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    closeModal() {
      this.$modal.hide('consent-recommend-modal');
    }
  },
};
</script>

<style>
</style>