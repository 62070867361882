import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios'
import router from './router';
import store from './store';
import AOS from 'aos';
import VModal from 'vue-js-modal';
import 'magnific-popup';

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper, /* { default options with global component } */);

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import 'aos/dist/aos.css';

import './assets/scss/tailwind.css';

import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.use(VueYouTubeEmbed);

import loadingIcon from './assets/image/icons/loading.svg'

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
  loading: loadingIcon,
});

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(VModal, {
  dialog: true
});
Vue.use(AOS);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize({
  en: {
    fields: {
      teamImg: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      teamName: {
        required: '必填',
        max: '字元過多',
        is: '隊名重複',
      },
      teamGroup: {
        is: '必填',
      },
      teamNumber: {
        is: '必填',
      },
      teamIntro: {
        required: '必填',
        max: '字元過多',
      },
      teamProject: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      teamRecommend: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      name0: {
        required: '必填',
        max: 20,
      },
      name1: {
        required: '必填',
        max: 20,
      },
      name2: {
        required: '必填',
        max: 20,
      },
      name3: {
        required: '必填',
        max: 20,
      },
      name4: {
        required: '必填',
        max: 20,
      },
      school0: {
        is: '必填',
      },
      school1: {
        is: '必填',
      },
      school2: {
        is: '必填',
      },
      school3: {
        is: '必填',
      },
      school4: {
        is: '必填',
      },
      department0: {
        is: '必填',
      },
      department1: {
        is: '必填',
      },
      department2: {
        is: '必填',
      },
      department3: {
        is: '必填',
      },
      department4: {
        is: '必填',
      },
      grade0: {
        is: '必填',
      },
      grade1: {
        is: '必填',
      },
      grade2: {
        is: '必填',
      },
      grade3: {
        is: '必填',
      },
      grade4: {
        is: '必填',
      },
      birth0: {
        required: '必填',
        regex: '格式錯誤',
      },
      birth1: {
        required: '必填',
        regex: '格式錯誤',
      },
      birth2: {
        required: '必填',
        regex: '格式錯誤',
      },
      birth3: {
        required: '必填',
        regex: '格式錯誤',
      },
      birth4: {
        required: '必填',
        regex: '格式錯誤',
      },
      proof0: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      proof1: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      proof2: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      proof3: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      proof4: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardF0: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardF1: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardF2: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardF3: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardF4: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
    
      identityCardB0: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardB1: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardB2: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardB3: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      identityCardB4: {
        required: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      consent0: {
        required: '必填',
        is: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      consent1: {
        required: '必填',
        is: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      consent2: {
        required: '必填',
        is: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      consent3: {
        required: '必填',
        is: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      consent4: {
        required: '必填',
        is: '必填',
        ext: '格式錯誤',
        size: '檔案過大',
      },
      phone0: {
        required: '必填',
        regex: '格式錯誤',
      },
      phone1: {
        required: '必填',
        regex: '格式錯誤',
      },
      phone2: {
        required: '必填',
        regex: '格式錯誤',
      },
      phone3: {
        required: '必填',
        regex: '格式錯誤',
      },
      phone4: {
        required: '必填',
        regex: '格式錯誤',
      },
      mail0: {
        required: '必填',
        email: '格式錯誤',
      },
      mail1: {
        required: '必填',
        email: '格式錯誤',
      },
      mail2: {
        required: '必填',
        email: '格式錯誤',
      },
      mail3: {
        required: '必填',
        email: '格式錯誤',
      },
      mail4: {
        required: '必填',
        email: '格式錯誤',
      },
      regulation: {
        is: '必填',
        required: '必填',
      },
    }
  }
})

library.add(faChevronUp, faChevronDown,faChevronRight, faChevronLeft, faPlus, faMinus, faTimes);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('Loading', Loading);

process.env.NODE_ENV === 'development' ? axios.defaults.baseURL = "/api" : axios.defaults.baseURL = "https://be-udreamer-hjrjj.ondigitalocean.app";
// axios.defaults.baseURL = "/api";

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    AOS.init({
      once: true,
      disable: window.innerWidth < 992,
    });
  },
}).$mount('#app');
