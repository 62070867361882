<template>
  <div id="register_form"
    class="register item_container">
    <loading :active.sync="isLoading"
              :height="48"
              :width="48"
              loader="spinner"
              color="#888"
              background-color="#a5a5a5"
    ></loading>


    <div class="img_wrapper">
      <div class="img_sun">
        <img src="https://lh3.googleusercontent.com/psr8qifLuf-9t5lbUlnXvkgTXh4wgLuVQvbs-S8_nrwtwWdtvoiKy08lOBpWhumnuQX2W_jgOLiqqDIOEMmeOBU9utb_ZVlUQZAfvK81Zsm7er167U0HWUN1fdhrbvw7FRpXRgkzCw=w2400" alt=""
          data-aos="fade-down-left"
          data-aos-duration="2000"
        >
      </div>

      <div class="img_plane">
        <img src="https://lh3.googleusercontent.com/3Rt5C6XT4AoAm_OfBOuQ1wbPq4ENaB0AREbhIb2DT7jGAfhMR6AEU3W2GwG1gMRuCR3zl9_5b6dSJVqeZYsWinYs_9OQ9FX34ZyjBZKQk7GeYPq4LIMQVeWtoq0B_kthk60l40wH3A=w2400" alt=""
          data-aos="fade-up-right"
          data-aos-duration="2000"
          >
      </div>

      <div class="item_wrapper regulation"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
        >
        <h2 class="sub_title">
          <img class="icon_roket"
          src="https://lh3.googleusercontent.com/SHd7ip2QHt8oRXpya4bptLIB6R-j6yVlCpF2edk5yGjcpM56JoNox86If3Z-M6OAFfavevAtb9iWRcs3K74lMq39E_Wg1iY9vpRgu0AwjZOMKFzeh3_MJ76HE--Th2rEF5YkUcd5Sw=w2400" alt=""
          >
          徵件辦法
        </h2>
        <div class="line">
          <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt="">
        </div>

        <div class="item_content">

          <div class="item">
            <p class="item_title">1. 徵件對象</p>
            <p class="content">
              -全國各地就讀大專院校學子(含碩士班)，每隊二至五人，歡迎組隊參加。
            </p>
          </div>

          <div class="item">
            <p class="item_title">2. 徵件組別</p>
            <p class="content">
              -專題組: 以聯合國永續發展目標「SDGs」為題，只要符合 17 項核心目標皆可做為專題。<br />
            </p>
            <p class="content">
              -公開組: 個人夢想、公益服務、文化交流、以及其他類型。
            </p>
          </div>

          <div class="item">
            <p class="item_title">3. 報名方式</p>
            <p class="content">
              -請登入報名網頁填寫報名資料。
            </p>
          </div>

          <div class="item">
            <p class="item_title">4. 初審徵件日期</p>
            <p class="content">
              -2021 年 08 月 01 日至 2021 年 09 月 30 日 中午 12 點止，逾期恕不受理。
            </p>
          </div>
          <div class="item">
            <p class="item_title">5. 獎勵方式</p>
            <p class="content">
              -總獎金新台幣 300 萬元。 依獲選計畫內容發給核定之補助獎金。
            </p>
          </div>

        </div>

        <a class="btn"
        href="/documents/2021_6th_udreamer_regulation.pdf"
        download
        >
          <img src="https://lh3.googleusercontent.com/5w9dnxueibY2dG8EkT__w9He-57ksE43hmTSABmTgJkASsM8RK7A95eyUMEKIYvxOy7FgRfgwP6hQGeO_-_R6bFsooMvntAc7Sv2OwApc89xveEITG_3zB4uDms3zaWp7y6THPhtMw=w2400" alt="">
        </a>

        <div class="line" v-if="registerShow">
          <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt="">
        </div>

<h2 style="margin-top: 36px !important; color: red; text-align: center" v-if="!registerShow">報名已截止，感謝各位同學的參與 !<br>如有任何疑問，請聯絡主辦單位。</h2>
<!-- 表單 -->
<ValidationObserver ref="form"
  class="validation_observer"
  v-slot="{ valid, handleSubmit }"
  v-else
  tag="div"
  >
  <form @submit.prevent="handleSubmit(onSubmit)" >
    
<!-- 檔案 團隊照片 -->
    <div class="r_item">
      <ValidationProvider ref="teamImgProvider"
        name="teamImg"
        class="validation_provider"
        :rules="{ required: true, 'ext': ['jpg', 'png'], size: 5120 }"
        v-slot="{ errors }"
        tag="div"
        >
          <p>團隊照片 (限 jpg, png，5MB):</p>
          <div>
            <input id="teamImg"
              type="file"
              accept=".jpg,.png"
              @change="handleTemaImg($event)"
              />
            <p class="error_txt"
              v-if="errors != ''"
              >
              {{ errors[0] }}
            </p>
          </div>
        </ValidationProvider>
      </div>


<!-- 團隊名稱 -->
    <div class="r_item">
      <ValidationProvider class="validation_provider"
        :rules="{ required: true,  is: !chkTeamName, max: 20 }"
        v-slot="{ errors }"
        tag="div"
        >
        <p>團隊名稱:</p>
        <div>
          <input id="teamName"
            type="text"
            v-model.trim="form.team.name"
            @blur="TeamName()"
            />
          <p class="error_txt"
            v-if="errors != ''"
            >
            {{ errors[0] }}
          </p>
        </div>
      </ValidationProvider>
    </div>

<!-- 參賽組別 -->
    <div class="r_item">
      <ValidationProvider class="validation_provider"
        :rules="{ is: form.team.group ===   'default' }"
        v-slot="{ errors }"
        tag="div"
        >
        <p>參賽組別:</p>
        <div>
          <select id="teamGroup"
            v-model="form.team.group"
            >
            <option value="default" selected disabled>
              -- 請選擇 --
            </option>
            <option value="p">公開組</option>
            <option value="s">專題組</option>
          </select>
          <p class="error_txt"
            v-if="errors != ''"
            >
            {{ errors[0] }}
          </p>
        </div>
      </ValidationProvider>
    </div>

<!-- 團隊人數 -->
    <div class="r_item">
      <ValidationProvider class="validation_provider"
        :rules="{ is: form.team.number === '0' }"
        v-slot="{ errors }"
        tag="div"
        >
        <p>團隊人數:</p>
        <div>
          <select id="teamNumber"
          v-model.trim="form.team.number">
            <option value="0" selected disabled>-- 請選擇 --</option>
            <option value="2">2 人</option>
            <option value="3">3 人</option>
            <option value="4">4 人</option>
            <option value="5">5 人</option>
          </select>
          <p class="error_txt"
            v-if="errors != ''"
            >
            {{ errors[0] }}
          </p>
        </div>
      </ValidationProvider>
    </div>

<!-- 團隊簡介 -->
    <div class="r_item">
      <ValidationProvider class="validation_provider"
        :rules="{ required: true, max: 10000 }"
        v-slot="{ errors }"
        tag="div"
        >
        <p>團隊及成員介紹 (可說明參賽動機及團隊分工):</p>
        <div>
          <textarea
            id="teamIntro"
            cols="30"
            rows="10"
            v-model="form.team.intro"
          ></textarea>
          <p class="error_txt"
            v-if="errors != ''"
            >
            {{ errors[0] }}
          </p>
        </div>
      </ValidationProvider>
    </div>

<!-- 檔案 計畫摘要 -->
    <div class="r_item">
      <ValidationProvider ref="teamProjectProvider"
        name="teamProject"
        class="validation_provider"
        :rules="{ required: true, 'ext': ['pdf'], size: 10240 }"
        v-slot="{ errors }"
        tag="div"
        >
        <p>計畫摘要 (兩頁 A4 企劃大綱，限 pdf，10MB):</p>
        <div>
          <input id="teamProject"
            type="file"
            accept=".pdf"
            @change="handleTemaProject($event)" 
            />
          <p class="error_txt"
            v-if="errors != ''"
            >
            {{ errors[0] }}
          </p>
        </div>
      </ValidationProvider>
    </div>

<!-- 檔案 推薦函 -->
    <div class="r_item">
      <validationProvider ref="teamRecommendProvider"
        name="teamRecommend"
        class="validation_provider"
        :rules="{ required: true, 'ext': ['jpg', 'png', 'pdf'], size: 5120 }"
        v-slot="{ errors }"
        tag="div"
        >
        <p>
          <a href="/documents/2021_6th_udreamer_consultant.docx" download>
            諮詢顧問老師同意書與推薦函</a> (下載檔案，填寫後上傳，限 jpg, png, pdf，5MB):
          </p>
        <div>
          <input id="teamRecommend"
            type="file"
            accept=".jpg,.png,.pdf"
            @change="handleTeamRecommend($event)"
            />
          <p class="error_txt"
            v-if="errors != ''"
            >
            {{ errors[0] }}
          </p>
        </div>
      </validationProvider>
    </div>




<!-- 成員資料--------------------------------------------------------------------->
<div v-for="(number, mkey) in parseInt(form.team.number)"
  :key="mkey"
  >
  <hr />
  <div class="r_item">
    <p class="member_title"
      v-if="mkey == 0"
      >
      隊長
    </p>
    <p class="member_title"
      v-else
      >
      隊員
    </p>

<!-- 姓名 -->
    <ValidationProvider :vid="'name' + mkey"
      class="validation_provider" 
      :rules="{ required: true, max:20 }" 
      v-slot="{ errors }" 
      tag="div"
      >
      <p>姓名:</p>
      <div>
        <input :id="'name' + mkey"
          type="text" 
          v-model.trim="form.member[mkey].name" 
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!--學校  -->
    <ValidationProvider :vid="'school' + mkey" 
      class="validation_provider" 
      :rules="{ is: form.member[mkey].school === 'default' }" 
      v-slot="{ errors }" 
      tag="div"
      >
      <p>學校:</p>
      <div>
        <select :id="'school' + mkey" 
          v-model="form.member[mkey].school"
          >
          <option value="default" selected disabled>-- 請選擇 --</option>
          <option :value="item"
            v-for="(item, key) in university"
            :key="'university' + key"
            >
            {{ item }}
          </option>
        </select>
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!-- 科系 -->
    <ValidationProvider :vid="'department' + mkey"
      class="validation_provider" 
      :rules="{ is: form.member[mkey].department === 'default' }"
      v-slot="{ errors }" 
      tag="div"
      >
      <p>科系:</p>
      <div>
        <select :id="'department' + mkey"
          v-model="form.member[mkey].department"
          >
          <option value="default" selected disabled>-- 請選擇 --</option>
          <option :value="item.department"
            v-for="(item, key) in allDepartments(form.member[mkey].school)"
            :key="'department' + key"
            >
            {{ item.department }}
          </option>
        </select>
        <p class="error_txt"
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!-- 年級 -->
    <ValidationProvider :vid="'grade' + mkey"
      class="validation_provider"
      :rules="{ is: form.member[mkey].grade === 'default' }" 
      v-slot="{ errors }" 
      tag="div"
      >
      <p>年級:</p>
      <div>
        <select :id="'grade' + mkey" 
          v-model="form.member[mkey].grade"
          >
          <option value="default" selected disabled>-- 請選擇 --</option>
          <option value="大一">大一</option>
          <option value="大二">大二</option>
          <option value="大三">大三</option>
          <option value="大四">大四</option>
          <option value="碩一">碩一</option>
          <option value="碩二">碩二</option>
          <option value="碩三">碩三</option>
        </select>
        <p class="error_txt"
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!-- 出生年月日 -->
    <ValidationProvider :vid="'birth' + mkey"
      class="validation_provider"
      :rules="{ required: true, regex: /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/}"
      v-slot="{ errors }"
      tag="div"
      >
      <p>出生年月日: </p>
      <div>
        <input :id="'birth' + mkey" 
          type="date"
          placeholder="yyyy-mm-dd"
          v-model="form.member[mkey].birth" 
          @change="ageCalculate(form.member[mkey].birth, mkey)"
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!-- 檔案 在校證明 -->
    <ValidationProvider ref="proofProvider"
      :name="'proof' + mkey"
      class="validation_provider"
      :rules="{ required: true , 'ext': ['jpg', 'png', 'pdf'], size: 5120 }"
      v-slot="{ errors }"
      tag="div"
      >
      <p>在校證明 (限 jpg, png, pdf，5MB):</p>
      <div>
        <input :id="'proof' + mkey"
          type="file"
          accept=".jpg,.png,.pdf"
          @change="handleProof(mkey, $event)"
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>

<!-- 檔案 身分證正面 -->
    <ValidationProvider ref="identityCardFProvider"
      :name="'identityCardF' + mkey"
      class="validation_provider"
      :rules="{ required: true, 'ext': ['jpg', 'png'], size: 5120 }"
      v-slot="{ errors }"
      tag="div"
      >
      <p>身分證正面 (限 jpg, png，5MB):</p>
      <div>
        <input :id="'identityCardF' + mkey"
          type="file"
          accept=".jpg,.png"
          @change="handleIdentityCardF(mkey, $event)"
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>

<!-- 檔案 身分證反面 -->
    <ValidationProvider ref="identityCardBProvider"
      :name="'identityCardB' + mkey"
      class="validation_provider"
      :rules="{ required: true, 'ext': ['jpg', 'png'], size: 5120 }"
      v-slot="{ errors }"
      tag="div"
      >
      <p>身分證反面 (限 jpg, png，5MB):</p>
      <div>
        <input :id="'identityCardB' + mkey"
          type="file"
          accept=".jpg,.png"
          @change="handleIdentityCardB(mkey, $event)"
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>

<!-- 檔案 未滿20歲同意書 -->
    <ValidationProvider :vid="'consent' + mkey"
      ref="consentProvider"
      :name="'consent' + mkey"
      class="validation_provider"
      :class="{ 'opacity_30': form.member[mkey].years > 19 }"
      :rules="{ required: form.member[mkey].consentFile.chk, 'ext': ['jpg', 'png', 'pdf'], size: 5120 }"
      v-slot="{ errors }"
      tag="div"
      >
      <p>
        <a href="/documents/2021_6th_udreamer_20consent.docx" download>未滿 20 歲參賽同意書</a> (下載檔案，填寫後截圖或掃描上傳，限 jpg, png, pdf，5MB): 
      </p>
      <div>
        <input :id="'consent' + mkey"
          type="file"
          accept=".jpg,.png,.pdf"
          @change="handleConsent(mkey, $event)"
          :disabled="form.member[mkey].years > 19"
          />
        <p class="error_txt" 
          v-if="errors != '' && form.member[mkey].years < 20"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!-- 手機 -->
    <ValidationProvider :vid="'phone' + mkey"
      class="validation_provider"
      :rules="{ required: true, regex: /^09[0-9]{8}$/ }"
      v-slot="{ errors }"
      tag="div"
      >
      <p>手機:</p>
      <div>
        <input :id="'phone' + mkey"
          type="text"
          v-model.trim="form.member[mkey].phone"
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>


<!-- 信箱 -->
    <ValidationProvider :vid="'mail' + mkey"
      class="validation_provider"
      :rules="{ required: true, email: true }"
      v-slot="{ errors }"
      tag="div"
      >
      <p>信箱:</p>
      <div>
        <input :id="'mail' + mkey"
          type="text"
          v-model.trim="form.member[mkey].mail"
          />
        <p class="error_txt" 
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </div>
    </ValidationProvider>




  </div>
</div>

    <ValidationProvider class="chk_b validation_provider"
      :rules="{ is: 1 }"
      v-slot="{ errors }"
      v-show="form.team.number > 0"
      tag="div"
      >
      <label>
        <input id="regulation"
          type="checkbox"
          v-model="form.regulation"
          :true-value="1"
          :false-value="0"
          />
        我已同意活動辦法、注意事項與個資蒐集聲明
        <p>
          <a href="/documents/2021_6th_udreamer_personal_info.pdf" target="_blank">2021 第六屆台積電青年築夢計畫個人資料蒐集、處理及利用同意書</a>
        </p>
        <p class="error_txt_center"
          v-if="errors != ''"
          >
          {{ errors[0] }}
        </p>
      </label>
    </ValidationProvider>

    <div class="r_item" v-show="form.team.number > 0">
      <input type="button" class="sub_btn btn" :class="{ 'opacity_30': false }" value="送出報名" @click.stop="onSubmit()">

    </div>
  </form>
</ValidationObserver>

      </div>

      <!-- <div class="img_cloud">
        <img src="@/assets/image/bg/img_A-04.png" alt="">
      </div>

      <div class="img_city">
        <img src="@/assets/image/bg/img_A-05a.png" alt="img_city">
      </div>

      <div class="img_cloudAndCity">
        <img src="@/assets/image/bg/bg-49-2.png" alt="img_city">
      </div> -->
    </div> 
  </div>
</template>

<script>
import universityList from '@/assets/data/university_list.json';

export default {
  data() {
    return {
      deadLine: 1632975000,
      registerShow: false,
      isLoading:false,
      teamUrl:'',
      teamId: '',
      chkTeamName: true,
      chkN: 400,
      form: {
        regulation: 0,
        team: {
          img: {
            fileName: '',
            dataUrl: '',
          },
          name: "",
          group: "default",
          number: 0,
          intro: "",
          projectFile: {
            fileName: '',
            dataUrl: '',
          },
          recommendFile: {
            fileName: '',
            dataUrl: '',
          },
        },
        member: [
          {
            name: "",
            school: "default",
            department: "default",
            grade: "default",
            birth: "",
            years: 0,
            proofFile: {
              fileName: '',
              dataUrl: '',
            },
            identityCard: {
              front: {
                fileName: '',
                dataUrl: '',
              },
              back: {
                fileName: '',
                dataUrl: '',
              },
            },
            consentFile: {
              chk: true,
              fileName: '',
              dataUrl: '',
            },
            phone: "",
            mail: "",
          },
          {
            name: "",
            school: "default",
            department: "default",
            grade: "default",
            birth: "",
            years: 0,
            proofFile: {
              fileName: '',
              dataUrl: '',
            },
            identityCard: {
              front: {
                fileName: '',
                dataUrl: '',
              },
              back: {
                fileName: '',
                dataUrl: '',
              },
            },
            consentFile: {
              chk: true,
              fileName: '',
              dataUrl: '',
            },
            phone: "",
            mail: "",
          },
          {
            name: "",
            school: "default",
            department: "default",
            grade: "default",
            birth: "",
            years: 0,
            proofFile: {
              fileName: '',
              dataUrl: '',
            },
            identityCard: {
              front: {
                fileName: '',
                dataUrl: '',
              },
              back: {
                fileName: '',
                dataUrl: '',
              },
            },
            consentFile: {
              chk: true,
              fileName: '',
              dataUrl: '',
            },
            phone: "",
            mail: "",
          },
          {
            name: "",
            school: "default",
            department: "default",
            grade: "default",
            birth: "",
            years: 0,
            proofFile: {
              fileName: '',
              dataUrl: '',
            },
            identityCard: {
              front: {
                fileName: '',
                dataUrl: '',
              },
              back: {
                fileName: '',
                dataUrl: '',
              },
            },
            consentFile: {
              chk: true,
              fileName: '',
              dataUrl: '',
            },
            phone: "",
            mail: "",
          },
          {
            name: "",
            school: "default",
            department: "default",
            grade: "default",
            birth: "",
            years: 0,
            proofFile: {
              fileName: '',
              dataUrl: '',
            },
            identityCard: {
              front: {
                fileName: '',
                dataUrl: '',
              },
              back: {
                fileName: '',
                dataUrl: '',
              },
            },
            consentFile: {
              chk: true,
              fileName: '',
              dataUrl: '',
            },
            phone: "",
            mail: "",
          },
        ],
      },
    };
  },
  computed: {
    // 取得所有學校，共152所
    university() {
      let allUniversity = new Set();
      universityList.filter(item => !allUniversity.has(item.university) ? allUniversity.add(item.university) : false)
      return allUniversity;
    },
  },
  methods: {
    alert(txt) {
      alert(txt);
    },
    // 取得該學校所有科系並移除在職專班、博士班、進修班
    allDepartments(u) {
      return universityList.filter(item => item.university === u && !item.department.match(/專班|在職|進修|博士/g))
    },
    // 計算年齡
    ageCalculate(date, key) {
      let birthTime = Date.parse(date);
      let year = 1000 * 60 * 60 * 24 * 365;
      let nowTime = new Date();
      let age = parseInt((nowTime - birthTime) / year);

      this.form.member[key].years = age;
      if(age < 20) {
        this.form.member[key].consentFile.chk = true;
      } else {
        this.form.member[key].consentFile.chk = false;
      }
    },
    // 檔案處理 - 團隊照片驗證及加至 data
    async handleTemaImg(element) {
      const vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      const valid = await vm.$refs.teamImgProvider.validate(file);

      if(valid.valid) {
        reader.onload = function(event) {
          const imgDataUrl = event.target.result;
          vm.form.team.img.fileName = file.name;
          vm.form.team.img.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    // 檔案處理 - 計畫摘要驗證及加至 data
    async handleTemaProject(element) {
      const vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      const valid = await vm.$refs.teamProjectProvider.validate(file);

      if(valid.valid) {
        reader.onload = function(event) {
          const imgDataUrl = event.target.result;

          vm.form.team.projectFile.fileName = file.name;
          vm.form.team.projectFile.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    // 檔案處理 - 老師同意書驗證及加至 data
    async handleTeamRecommend(element) {
      const vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      const valid = await vm.$refs.teamRecommendProvider.validate(file);

      if(valid.valid) {
        reader.onload = function(event) {
          const imgDataUrl = event.target.result;

          vm.form.team.recommendFile.fileName = file.name;
          vm.form.team.recommendFile.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    // 檔案處理 - 在校證明及加至 data
    async handleProof(key, element) {
      const vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      const valid = await vm.$refs.proofProvider[key].validate(file);

      if(valid.valid) {
        reader.onload = function(event) {
          const imgDataUrl = event.target.result;

          vm.form.member[key].proofFile.fileName = file.name;
          vm.form.member[key].proofFile.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    // 檔案處理 - 身分證正面及加至 data
    async handleIdentityCardF(key, element) {
      const vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      const valid = await vm.$refs.identityCardFProvider[key].validate(file);

      if(valid.valid) {
        reader.onload = function(event) {
          const imgDataUrl = event.target.result;

          vm.form.member[key].identityCard.front.fileName = file.name;
          vm.form.member[key].identityCard.front.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    // 檔案處理 - 身分證反面及加至 data
    async handleIdentityCardB(key, element) {
      const vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      const valid = await vm.$refs.identityCardBProvider[key].validate(file);
      
      if(valid.valid) {
        reader.onload = function(event) {
          const imgDataUrl = event.target.result;

          vm.form.member[key].identityCard.back.fileName = file.name;
          vm.form.member[key].identityCard.back.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      }
    },
    // 檔案處理 - 未滿20歲同意書及加至 data
    async handleConsent(key, element) {
      let vm = this;
      
      let file = element.target.files[0];
      let reader = new FileReader();
      let valid = await vm.$refs.consentProvider[key].validate(file);

      if(valid.valid) {
        reader.onload = function(event) {
          let imgDataUrl = event.target.result;

          vm.form.member[key].consentFile.fileName = file.name;
          vm.form.member[key].consentFile.dataUrl = imgDataUrl;
        };
        reader.readAsDataURL(file);
      } 
    },
    // 檢查隊名有無重複
    TeamName() {
      let data = {
        name: this.form.team.name,
      };

      this.$http({
        method: 'post',
        url: '/name/check',
        data: data,
      }).then(response => {
        if(response.data.status = 400) {
          this.chkTeamName = true;
        } else {
          this.chkTeamName = false;
        }
      }).catch(err => {
        if(err.response.status) this.chkTeamName = false;
      })
    },
    addNewTeam() {
      this.isLoading = true;
      let data = {
        name: this.form.team.name,
        img: this.form.team.img.dataUrl,
        group: this.form.team.group,
        intro: this.form.team.intro,
        projectFile: this.form.team.projectFile.dataUrl,
        recommendFile: this.form.team.recommendFile.dataUrl,
      };

      this.$http({
        method: 'post',
        headers: {"Content-Type": "application/json"},
        url: '/team/',
        data: JSON.stringify(data),
      }).then(response => {
        this.teamUrl = response.data.url
        this.teamId = response.data.id

        //新增隊員
        this.addAllMember(response.data.url)

      }).catch(err => {
        if(err.response.status === 400){
          alert('error');
        }
      })
    },
    async addAllMember(url) {
      let vm = this;
      for (let i = 0; i < vm.form.team.number; i++) {
        await vm.addNewTeamMember(i, url)
      }
    },
    async addNewTeamMember(key, url) {
      let vm = this;
      let data;
      if(this.form.member[key].years > 19) {
        data = {
          name: this.form.member[key].name,
          school: this.form.member[key].school,
          department: this.form.member[key].department,
          grade: this.form.member[key].grade,
          birth: this.form.member[key].birth,
          schoolproof: this.form.member[key].proofFile.dataUrl,
          front: this.form.member[key].identityCard.front.dataUrl,
          back: this.form.member[key].identityCard.back.dataUrl,
          phone: this.form.member[key].phone,
          mail: this.form.member[key].mail,
          team: url,
        }
      } else {
        data = {
          name: this.form.member[key].name,
          school: this.form.member[key].school,
          department: this.form.member[key].department,
          grade: this.form.member[key].grade,
          schoolproof: this.form.member[key].proofFile.dataUrl,
          birth: this.form.member[key].birth,
          front: this.form.member[key].identityCard.front.dataUrl,
          back: this.form.member[key].identityCard.back.dataUrl,
          consentFile: this.form.member[key].consentFile.dataUrl,
          phone: this.form.member[key].phone,
          mail: this.form.member[key].mail,
          team: url,
        }
      }

      await this.$http({
        method: 'post',
        headers: {"Content-Type": "application/json"},
        url: '/teammember/',
        data: JSON.stringify(data),
      }).then(response => {
        if (key === vm.form.team.number - 1) {
          this.isLoading = false;
          this.$modal.show('thanks-page-modal');
        }

      }).catch(err => {
        if(err.response.status) {
          this.delTeam(this.teamId)
          this.isLoading = false;
          alert('error');
        }
      })
    },
    delTeam(id) {
      this.$http({
        auth: {
          username: 'atona',
          password: "8P{87hqjVKD%3z'\\",
        },
        method: 'delete',
        url: `/team/${id}/`,
      }).then((response)=> {
        this.teamId = ''
      })
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if(success) {
          this.addNewTeam();
        } else {
          alert('資料未填寫完畢')
        }
      })
    },
  },
  mounted() {
    // Date.now() >= new Date(this.deadLine * 1000) ? this.registerShow = false : this.registerShow = true;
    // if(this.registerShow) this.$modal.show('consent-recommend-modal');
    // this.getTeam();
  },
}
</script>


<style lang="scss" scoped>

  .item_container {
    background: url('https://lh3.googleusercontent.com/8na-WXjzIBtC6fVlzr1FH8TnvIVauVoUpWapGgxb5wLqEg3YeGODEvl4va8B3e5C3RIR1aO3oFPI8y9EUKPJhoeyAQscJxV3MKKF9jCm8kbneJnFSaoafxb1-jb_BgS0nsQKKZ64vQ=w2400');
    @apply relative flex-col justify-between pt-32 lg:pt-0 overflow-hidden;

    .item_wrapper {
      @apply lg:w-90% xl:w-full items-center lg:mt-40 xl:mb-56 z-20;

      .item_content {
        @apply w-5/6 text-center mt-8;

        .item {
          @apply my-4 text-left;

          .item_title {
            @apply text-red-500 font-semibold;
          }

          .content {
            @apply ml-4 font-semibold;

            span {
              @apply font-normal;
            }
          }
        }
      }
    }
  }

  .regulation {
    @apply lg:p-16 lg:pt-0 pb-12 rounded-3xl bg-white bg-opacity-90;
  }

  .txt_red {
    @apply text-base text-red-400 font-semibold;
  }

  .liner {
    @apply mx-4 border-current border-r text-xl lg:text-3xl;
  }
  
  .img_sdgs {
    @apply w-3/5 md:w-3/5 xl:w-4/5 m-auto;
  }

  .img_expand {
    @apply w-56 m-auto lg:w-72;
  }

  .img_sun {
    @apply absolute w-40 lg:w-56 top-16 right-8 m-0;
  }

  .img_plane {
    @apply absolute w-40 lg:w-56 top-96 left-28 m-0;
  }

  .img_city {
    @apply hidden lg:block absolute lg:-bottom-4 -bottom-4;
  }


  .img_cloud {
    @apply hidden lg:block absolute lg:bottom-0;
  }

  .img_cloudAndCity {
    @apply block xl:hidden relative -bottom-4;
  }

  img {
    @apply m-auto;
  }
  
  .btn {
    @apply w-72 mt-16 self-center text-center mx-auto px-6 py-3 cursor-pointer focus:outline-none;
    
    img {
      @apply relative filter drop-shadow-line hover:top-1;
    }
  }





  .r_item {
    @apply text-center;
  }

  .validation_observer{
    @apply w-full;

    .validation_provider {
      @apply flex-col lg:flex-row flex justify-between items-start m-4;

      input[type=text], input[type=file], input[type=date], textarea, select {
        @apply w-full border border-current bg-white;
      }
      p {
        @apply mr-4 text-left lg:max-w-50%;
      }
      a {
        @apply text-blue-500 underline;
      }
      label {
        @apply m-auto;
      }
      


      .error_txt {
        @apply text-right max-w-none #{!important};
        @apply text-red-400;
      }

      .error_txt_center {
        @apply  text-center #{!important};
        @apply text-red-400;
      }
    }
  }

  .member_title {
    @apply text-center m-4 text-2xl font-semibold;
  }

  hr {
    @apply w-full;
  }

.opacity_30 {
  @apply opacity-30;
}

.sub_btn {
  @apply border border-2 bg-white hover:bg-green-300 rounded-full border-green-300 text-green-300 hover:text-white font-semibold disabled:opacity-30 disabled:bg-white disabled:text-green-300;
}

.chk_b {
  @apply  mt-8;

  &>label {
    @apply text-center;
    &>p {
      @apply w-full max-w-none text-center #{!important};
    }
  }
}

</style>
