<template>
  <div class="item_container">
    <div class="item_wrapper">
      <div class="item_row">
        <div class="item">
          <p>本網站使用cookies以提昇您的使用體驗及統計網路流量相關資料。繼續使用本網站表示您同意我們使用cookies。我們的<a href="https://www.tsmc-foundation.org/about/policy.aspx" target="_blank">隱私及Cookies政策</a>提供更多關於cookies使用及停用的相關資訊。</p>
        </div>
        <div class="item">
          <button class="btn" @click="closeGDPR">我接受</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mutations } from '@/store/store.js'
import Cookies  from 'js-cookie';

export default {
  methods: {
    GDPRToggle: mutations.GDPRToggle,
    closeGDPR() {
      this.GDPRToggle(false)
      Cookies.set('udreamer_PReg', Math.random().toString(36).substring(2), { expires: 30 });
    }
  }
}
</script>

<style lang="scss" scoped>
  .item_container {
    @apply fixed flex bottom-0 h-auto bg-line_gray opacity-90 text-white z-100 overflow-visible;;

    .item_wrapper {
      @apply w-98 max-w-screen-xl h-full flex-row justify-between p-0 m-0 #{!important};

      .item_row {
        @apply h-full flex flex-col justify-center items-center mx-auto my-6;

        .item {
          @apply flex h-full flex-col justify-center m-auto mt-6;
        }
      }
      
    }
  }

  a {
    text-decoration: underline;
  }

  .btn {
    // border: 1px solid #fff;
    // padding: 4px 8px;
    @apply border-current border px-4 py-1 hover:bg-white hover:text-line_gray focus:bg-white focus:text-line_gray;
  }
  

</style>
