<template>
  <transition transition="pop-out">
    <modal name="thanks-page-modal" width="400" height="auto" :adaptive="true" @before-close="beforeClose">
      
      <div class="box">
        <div class="box-logo">
				
        </div>
        <div class="box-part">

          <div class="partition" id="partition-register">

            <div class="partition-title">報名資料已成功送出</div>

            <div class="partition-form">
              <div class="button-set">
                  <button class="large-btn cancel-btn" @click="closeModal()">回首頁</button>
              </div>
            </div>

          </div>
          
        </div>
      </div>

    </modal>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    beforeClose(event) {
      let vm = this;
      vm.showModal = true;

      if(this.showModal) {
        event.cancel();
      }
    },
    closeModal() {
      let vm = this;
      vm.showModal = false;
      this.$modal.hide('consent-recommend-modal');
      vm.showModal = true;
      
      this.$router.push('/');
    }
  },
};
</script>

<style>
</style>