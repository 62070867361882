<template>
  <div id="seminar"
    class="seminar item_container"
    >
    <div class="img_wrapper" >

      <div class="item_wrapper" ref="seminar" >
        
          <h2 class="sub_title"
            data-aos="fade"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
            <img class="icon_title"
              src="https://lh3.googleusercontent.com/O2XC3SwGR1SbIiExiyY-_scfOu7cvzawytQn3LhgbDH8XHFDOymvbuOZeghqFEWySyHM7ArqzYarNDYf8CwMybzFZP_2EJ74gR3nni5GkCwllVhrH1JCBl3t6M_6xNViTArGcDwz8Q=w2400" alt=""
              data-aos="zoom-in"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
            校園說明會
          </h2>
          <div class="line">
            <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt=""
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
          </div>

        <div class="item_content">
          <div class="item">
            <p
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
              針對有興趣參與青年築夢計畫學生進一步了解第六屆活動內容。<br />
              說明會當天將邀請歷屆團隊分享精采的築夢經驗與團隊現場實作體驗，<br />
              與有志參與同學傳承並交流互動。
            </p>
            <div class="card"
              v-for="item in seminar"
              :key=item.title
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-delay="300"
              data-aos-anchor-placement="bottom-bottom"
              >
              <div class="card_img">
                <img :src="item.img">
              </div>
              <div class="card_item">
                <div class="card_content">
                  <h3>{{ item.title }}</h3>
                  <p v-html="item.content"></p>
                </div>
                <a class="btn_red"
                  :href="item.url"
                  v-if="item.url !== ''"
                  target="_blank"
                  >
                  立即報名
                </a>
                <a class="btn_red"
                  @click="alert('尚未開放')"
                  v-else
                  >
                  立即報名
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="item seminar_ground">
        <img src="https://lh3.googleusercontent.com/kQ0eY8e8KgvixCmKqOKb9mYCKDv6lyIuf_28vmaXVzQoW81m-1g0ItRaKfDfhEnQ5kY3bAPaHv7R-SmfeMAWCEgdSc6ZD4FMvz53RdU6zf374mPJlDZ9YK8XeKbCzVap_kLjc2h1RQ=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="center-bottom"
          >
      </div>

      <div class="item img_man">
        <img src="https://lh3.googleusercontent.com/3ADKRAvC4cnqyMme-KK48vsKC7WFtF_j63IJdfWAIdVZswuR1us-ZmpWYSu662FRt8J1krsT-K-bt2IxDX2wP6Lb-55xzazu5gguVwtP_yRFISu2n2G9aTIRtQONobzx_374RPpuDA=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"

          >
      </div>

      <div class="item img_bike">
        <img src="https://lh3.googleusercontent.com/aSvHNQ1H4-lz2lfqutaaazRrhB8LmFVSKDsZvw6cBWsh0BTJ-aE29gfMRxiGZnoKqSyE8aIY4nf6LNSNqEdoVXSe5Ceal9fW88OQpUjlxBLECDIRvy-kln4d8Zp6IeIPXT-UWaL0nw=w2400" alt=""
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item img_fence">
        <img src="https://lh3.googleusercontent.com/oAHnIp8biq8iIcmFKZSZkF55veLaoNCRvTLJcBqWwI9qpONEITImYR0jRhrFtCaP1qfeq8csxMsIV7j9ok1KsiScahXIZYd8Edq2oAPROQFLgxVe6YCm-MiDg2oi2vrAWKK_57Bv4A=w2400" alt=""
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item img_d_idea">
        <img src="https://lh3.googleusercontent.com/oZqnpRrE38urT5PUSkc1oZmEAkwQ77QPNf5A8IaC2liKaXTUE__roChL65ZmjDvhOGSTF7T2Hi0Gz8MYTPG3NGx2kE1r3mr_hHAdcv8vnwNf01PxtbCo7znRl1CWikftByrYNgVklA=w2400" alt=""
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item img_d_bike">
        <img src="https://lh3.googleusercontent.com/cJpcJOobuo8Z6W3N7qwLFeVucfqDM0V8ddVVAosq2ow7EPdJEFuJzmzgLFCaMRTg6Huha9qHuUOivn_FwrOIlwWsZlzz76q-784GEkjuo7tS63c6Lh_L1FfloHWTkgKdceq-kQ16HQ=w2400" alt=""
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

      <div class="item seminar_ground_m">
        <img src="https://lh3.googleusercontent.com/xHthqZBCfZB7CJ8oCPUbYEgW6nJExJjaPQ0J2JDm-rbwEWpBzu0ILPKLgmA9ge7qcHefXxAlMidxuaysktkAMdQKr4T-xlj7GP9xl24mFzjb_XqaNPGVJcsP5mvhyjAQu9xXwWK4ZQ=w2400" alt=""
          data-aos="fade"
          data-aos-duration="1000"
          data-aos-anchor-placement="bottom-bottom"
          >
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        seminar: [
          {
            'img': 'https://lh3.googleusercontent.com/Hyu0xmT24UIz_NT8hnV_EAcvcbExmKS7DgMML3U6neYxrutN1ph37Ch1E5cLqix23J_bYIhcDbkHv_JKYNgDcvP2RoxfDYyDPcr5PPbFqjceagtzRQCIFWC0b5fA1o90AgoL7KOpSg=w2400',
            'title': '線上',
            'content': `日期: 2021/7/31 (六)<br />
            時間: 14:00~16:00`,
            'url' : 'https://bit.ly/3hYD2qX',
          },
          {
            'img': 'https://lh3.googleusercontent.com/XSVDcLjA1aiKUIudQfUAVofMEtnC96V1DwbqjBFNva1kFxxEYNNBYQ69WDXuLNkgekfwoJpM_Pfyl3Uzv80158HJzuYwUCR7RYtMPjiVDRPqoS-aE5jgniH1sRe4YEpDgoezpwSUrg=w2400',
            'title': '跨界對談',
            'content': `日期: 2021/9/7 (二) <br />
            時間: 11:00~12:35<br />
            地點: 孫運璿科技人文紀念館 B1講堂`,
            'url': 'https://lihi1.cc/8ljGM',
          },

        ]
      }
    },
    methods: {
      alert(txt) {
        alert(txt);
      },
    },
  }
</script>

<style lang="scss" scoped>

  .item_container {
    background: url('https://lh3.googleusercontent.com/p1qhEl7iZmeyOiOQkzA4BP3njx9rcWbw8-rvCVU-6bcVhCbxUqUCHCUpZHsL3PRu6pfQmscXDMeT0hyPkda85mCkgMWenzo0OAL_sgAD_ZLRPEC859fficISCbujdRuxyNakAoDiMA=w2400');
    @apply flex-col relative; 

    .item_wrapper {

      .item_content {
        @apply flex flex-col justify-around;

        .item {
          @apply w-4/5 lg:w-4/6;

          &>p {
            @apply text-center;
          }
        }
      }
    }
  }

  .card {
    background: url('https://lh3.googleusercontent.com/p1qhEl7iZmeyOiOQkzA4BP3njx9rcWbw8-rvCVU-6bcVhCbxUqUCHCUpZHsL3PRu6pfQmscXDMeT0hyPkda85mCkgMWenzo0OAL_sgAD_ZLRPEC859fficISCbujdRuxyNakAoDiMA=w2400');
    @apply flex w-full max-w-sm lg:max-w-none h-auto flex-col lg:flex-row text-left m-auto p-4 my-8 border border-current rounded-lg;

    .card_img {
      @apply w-full lg:w-1/3;

      img {
        @apply w-full h-full m-auto object-cover rounded-md;
      }
    }

    .card_item {
      @apply w-full lg:w-2/3 flex flex-col justify-between pt-4 px-4;

      .card_content {

        h3 {
          @apply mb-2;
        }
      }
    }
  }

  .btn_red {
    @apply w-32 text-center self-center lg:self-end mt-8 lg:mt-4 px-4 py-1 bg-btn_red text-white rounded-md border border-black focus:outline-none filter hover:brightness-90 cursor-pointer;
  }

  .seminar_ground {
    @apply hidden lg:block;
  }

  .seminar_ground_m {
    @apply block lg:hidden;
  }

  .img_fence {
    @apply hidden w-full lg:block lg:absolute lg:-bottom-1%;
  }

  .img_bike {
    @apply hidden w-1/12 lg:block lg:absolute lg:-bottom-1% animate-bike_drive2;
  }

  .img_man {
    right: 10%;
    bottom: 5%;
    @apply hidden w-1/12 lg:block lg:absolute;
  }

  .img_d_bike {
    @apply hidden lg:block lg:absolute w-5% right-15% bottom-12% origin-bottom-right animate-idea_pulse;
  }

  .img_d_idea {
    @apply hidden lg:block lg:absolute w-5% right-18% bottom-10% origin-bottom-right animate-idea_pulse;
  }

</style>
