<template>
  <div id="register" class="register_page">
    <ConsentAndRecommendModal />
    <ThanksPageModal />
    <Dropdown />
    <Navbar />
    <Form />
  <Footer />
  </div>
</template>

<script>
import Dropdown from '@/components/DropdownNav.vue';
import Navbar from '@/components/Nav.vue';
import Form from '@/components/register/Form.vue';
import Footer from '@/components/Footer.vue';
import ConsentAndRecommendModal from '@/components/modal/CandR.vue';
import ThanksPageModal from '@/components/modal/ThanksPage.vue';

export default {
  components: {
    Dropdown,
    Navbar,
    Form,
    Footer,
    ConsentAndRecommendModal,
    ThanksPageModal
  },
  mounted() {
    // this.$modal.show('consent-recommend-modal');
    document.documentElement.style.overflow = "auto";
    document.documentElement.style.webkitOverflowScrolling = "auto";
  },
};
</script>
