<template>
  <transition name="fade">
    <div class="back_to_top" v-show="showTop">
      <img src="https://lh3.googleusercontent.com/DbdiBElPDBztN0lO_LP0K88MawphSMFp6u5oiDtWPyFL_nUXiMWxjoxfKXkjvmrrUma5QBBTo9n1NSFtCxyh2m91HLgHqALW0mh2BWE2zUjwDjqvVh_2OtjzMCGKVNw01SEMIJGc4w=w2400" alt=""
        @click="backToTop"
        >
    </div>
  </transition>
</template>

<script>
  export default {
    data() {
      return {
        showTop: false,
      }
    },
    methods: {
      backToTop() {
        document.querySelector('#kv').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      },
      scrollToTop() {
        if(document.querySelector('.back_top')) {
          let scrollTop = document.documentElement.scrollTop;
          let newTop = document.querySelector('.back_top').offsetTop;

          if(scrollTop < newTop) this.showTop = false;
          else this.showTop = true;
        }
      },
    },
    mounted() {
      window.addEventListener('scroll', this.scrollToTop);
    },
  }
</script>

<style lang="scss" scoped>

  .back_to_top {
    @apply hidden fixed lg:block w-20 right-12 bottom-24 cursor-pointer z-50;
  }

</style>
