<template>
  <div id="qa" class="qa item_container">
    <div class="item_wrapper" ref="qa">

      <div class="item_content">
        <h2 class="sub_title"
          data-aos="fade"
          data-aos-duration="2000"
          data-aos-delay="300"
          data-aos-anchor-placement="bottom-bottom"
          >
          <img class="icon_title"
            src="https://lh3.googleusercontent.com/QXfdIudiyFDs8Y6LdW1-RnVKUJcnZstEojm_xKxtVtVNtOub3jhhmAc92Zrkcr-w3i04QErUruWFwvWe5rKXBVIBaCBun4sT90AB8G8XJUyjiE4boWZ4UmF0YO0oFn44GHQ_vh3FxA=w2400" alt=""
            data-aos="zoom-in"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
          常見問題
        </h2>
        <div class="line">
          <img src="https://lh3.googleusercontent.com/IhfOsMZZBzoX2a2eOpbYwwPnG61tXo6_7fsDTcYAsJHftJjgXNYj2wvEe14oSM17wxDB4rApezoyUP7WKFrDUsgPPYB3bIxVG_ldEmvqPcdWvYV5Odx1p8bVNXSE8HQTJZPV3VBiXg=w2400" alt=""
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="300"
            data-aos-anchor-placement="bottom-bottom"
            >
        </div>
        <div class="item" 
          v-for="(item, key) in qa"
          :key="item.question"
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-delay="300"
          data-aos-anchor-placement="bottom-bottom"
          >

          <div class="col" :class="{ 'rounded_b': !item.show }"
            @click="qaToggle(key)">
            <img class="img_exclamation"
              src="https://lh3.googleusercontent.com/BdLg2hwPBhahAKL9xMpCFdtdsCQCfnMk1vbEVtssJXgOZu2AYSi-OXVHQ9aeosTt_i6dHhDJWrh8_v5TA8HLHwkpL7rDn-3XOehjkfAgXqe8B5jKBrYca_-GOPR1QZmr-jE6FnCpFg=w2400" alt=""
              >
            <span class="question">
              {{ item.question }}
            </span>
            <font-awesome-icon class="font_minus"
              :icon="['fas', 'minus']"
              v-if="item.show"
              />
            <font-awesome-icon class="font_plus"
              :icon="['fas', 'plus']"
              v-else 
              />
          </div>

          <transition-group name="fade" tag="div">
            <div class="draw"
              v-show="item.show"
              :key="'list' + key"
              >
              <p v-html="item.answer"></p>
            </div>
          </transition-group>

        </div>
      </div>

      <router-link class="btn"
        to="/register"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-anchor-placement="bottom-bottom"
        >
        <img src="https://lh3.googleusercontent.com/vuGoQvJ7uASl4xfq6z1C3VEZGmXrcayB-q92mEHDvt1G61hjEdjNKhCiSfqsfzghCTWyCleG2xfvCacoF1Q0afd2nVjWz__BrNcrkNk_OW9FvY0Lvu8zyDZXdYoCIVOgbkUMNZDfOg=w2400" alt="register">
      </router-link>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        qa: [
          {
            show: 0,
            question: '如果我想參與築夢計畫，主題該如何設定？',
            answer: `本次徵件計畫類型分為公開組及專題組。<br />
            公開組可針對個人夢想、公益服務、文化交流、其他類型進行發想與投稿。<br />
            專題組請依照今年的專題「SDGs」發想。不限任何形式，以符合 SDGs 作為指標、環境永續設為目標，提出夢想中的永續計畫！<br />
            第一階段初審將根據「參與動機與創意、可行性、影響力」給予評分。`,
          },
          {
            show: 0,
            question: '如果對於報名方式或活動內容有其他疑問，可以聯絡誰？',
            answer: `可以寄信至活動指定信箱 tsmcudreamer@atona.com<br />
            或電洽 02-2717-5856 活動團隊。`,
          },
          {
            show: 0,
            question: '參加資格有年齡限制嗎？',
            answer: '本活動沒有年齡限制，但全體團隊成員皆須為大專院校在學學生（含碩士班，不含在職專班）。',
          },
          {
            show: 0,
            question: '一組團隊至少要幾名隊員才能報名參與呢？',
            answer: '每組團隊成員至少2人，最多5 人，歡迎組隊報名。',
          },
          {
            show: 0,
            question: '請問投稿的 2 頁 A4 企劃大綱該如何呈現？',
            answer: '請濃縮完整計畫內容以精華呈現，說明實際執行辦法及預期成效和目標。內容可以包含但不限文字、表格、圖片等。',
          },
          {
            show: 0,
            question: '如果我的計畫參與過其他類似的競賽，可以再次以同樣的內容參加嗎？',
            answer: '如果您的夢想計畫先前已經獲得其他獎項補助，即不符合本次活動規則。',
          },
          {
            show: 0,
            question: '參加這個計畫徵選時，我的計畫還能參加其他相同性質的活動嗎？',
            answer: '基於築夢推手的立場上，我們鼓勵您多方嘗試。但本活動規定不重複資助已經獲得贊助的計畫，若您的計畫同時在本活動及其他活動中勝出，請您擇一參加，不然將會視為違反規定取消資格。',
          },
        ]
      }
    },
    methods: {
      qaToggle(t) {
        let vm = this;
        vm.qa[t].show = !vm.qa[t].show;
      },
    },
  }
</script>

<style lang="scss" scoped>

  .item_container {
    background: url('https://lh3.googleusercontent.com/p1qhEl7iZmeyOiOQkzA4BP3njx9rcWbw8-rvCVU-6bcVhCbxUqUCHCUpZHsL3PRu6pfQmscXDMeT0hyPkda85mCkgMWenzo0OAL_sgAD_ZLRPEC859fficISCbujdRuxyNakAoDiMA=w2400');

    .item_wrapper {

      .item_content {
        @apply flex w-full flex-col;

        .item {
          @apply w-4/5 lg:w-2/3 filter drop-shadow-line;

          .col {
            @apply relative w-full p-3 bg-second rounded-t-2xl transition-all duration-500 ease-linear cursor-pointer;

            img, span{
              @apply align-middle m-1 ;
            }
          }
          
          p {
            @apply px-4 text-gray-500;
          }
        }
      }
    }
  }

  .question {
    @apply inline-block w-4/5 lg:w-11/12 pr-8 text-white;
  }

  .draw {
    @apply p-3 bg-white rounded-b-2xl border-t-2;
  }

  .rounded_b {
    @apply rounded-b-2xl;
  }

  .img_exclamation {
    @apply inline w-4;
  }

  hr {
    @apply my-4 #{!important};
    @apply w-full border-white;
  }

  .btn {
    @apply w-64 self-center text-center mt-36 mx-auto px-6 py-3 cursor-pointer;
    
    img {
      @apply relative filter drop-shadow-line hover:top-1;
    }
  }
  
  .font_minus, .font_plus {
    @apply absolute align-middle right-8 top-1/2 transform -translate-y-1/2 text-white transition-all duration-1000 ease-linear;
  }

</style>
