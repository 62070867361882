<template>
  <div id="home" class="home_page">
    <Dropdown />
    <Navbar />
    <!-- <img src="@/assets/image/kv_1.jpg" alt=""> -->
    <KeyVision />
    <Origin />
    <News />
    <Schedule />
    <Seminar />
    <Workshop />
    <Qa />
    <Contact />
    <Footer />
    <BackToTop />
  </div>
</template>

<script>
import $ from 'jquery';
import Cookies  from 'js-cookie';

import Dropdown from '@/components/DropdownNav.vue';
import Navbar from '@/components/Nav.vue';
import KeyVision from '@/components/home/KeyVision.vue';
import Origin from '@/components/home/Origin.vue';
import News from '@/components/home/News.vue';
import Schedule from '@/components/home/Schedule.vue';
import Seminar from '@/components/home/Seminar.vue';
import Workshop from '@/components/home/Workshop.vue';
import Qa from '@/components/home/Qa.vue';
import Contact from '@/components/home/Contact.vue';
import Footer from '@/components/Footer.vue';
import BackToTop from '@/components/BackToTop.vue';

export default {
  components: {
    Dropdown,
    Navbar,
    KeyVision,
    Origin,
    News,
    Schedule,
    Seminar,
    Workshop,
    Contact,
    Qa,
    Footer,
    BackToTop,
  },
  mounted() {
    document.documentElement.style.overflow = "auto";
    document.documentElement.style.webkitOverflowScrolling = "auto";

    // if(!Cookies.get('udreamer_ad')) {
    //   Cookies.set('udreamer_ad', Math.random().toString(36).substring(2), { expires: 1/24 });

    //   $.magnificPopup.open({
    //     items: {
    //       src: 'https://www.youtube.com/watch?v=N4Ta7UeVydI?autoplay=1&mute=1',
    //     },
    //     type: 'iframe',
    //     mainClass: 'mfp-fade',
    //     removalDelay: 300,
    //     preloader: false,
    //   }, 0)
    // }
  },
};
</script>

<style lang="scss" scoped>
  
</style>
